/**
 * Frame by Miam Studio
 *
 * @author Thomas Galland <thomas@miamstudio.com>
 * @copyright Miam Studio <https://www.miamstudio.com>
 * @version 1.0
 * @license Private License
 * 
 */

/* 
    Globale functions
*/
Array.prototype.remove = function () {
    var what, a = arguments, L = a.length, ax;
    while (L && this.length) {
        what = a[--L];
        while ((ax = this.indexOf(what)) !== -1) {
            this.splice(ax, 1);
        }
    }
    return this;
};

Array.prototype.unique = function() {
    return this.reduce (
        function (a, b) {
            if (a.indexOf(b) < 0) {
                a.push(b);
            }

            return a;
        }
        ,[]
    );
};

function uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
};

function onYouTubeIframeAPIReady() {
    const event = new Event('youtubeApiReady');
    document.dispatchEvent(event);
};

(function ($) {
    let initialSortedGroup =[];
    let isYoutubeApiInsert = false;
    let isYoutubeApiLoaded = false;
    let lastScrollTop = 0;

    const minZindex = 1000,
        showAction = 'show',
        hideAction = 'hide',
        scrollAction = 'scroll',
        scrollActionDuration = 'scroll-duration';

    const touchDevice = (navigator.maxTouchPoints || 'ontouchstart' in document.documentElement);

    const numberRegex = /[0-9]+/, pxRegex = /px/, percentRegex = /%/, vhRegex = /vh/, urlRegex = /url\(['"]*(.*?)['"]*\)/g;

    const youtubeRegex = /(?:https?:\/{2})?(?:w{3}\.)?youtu(?:be)?\.(?:com|be)(?:\/watch\?v=|\/)([^\s&]+)/;
    /* 
        Private functions
    */
   
    const loadYoutubeApi = function (callback) {
        if (false === isYoutubeApiInsert) {
            var tag = document.createElement('script');
            tag.src = "https://www.youtube.com/iframe_api";

            $(document.body).append('<script src="https://www.youtube.com/iframe_api" />');

            isYoutubeApiInsert = true;
        }

        if (false === isYoutubeApiLoaded) {
            $(document).on('youtubeApiReady', function (event) {
                isYoutubeApiLoaded = true;
                callback();
            }); 
        } else {
            callback();
        }
    };

    const getYoutubeId = function (url) {
        return url.match(youtubeRegex)[1];
    };

    const blockScroll = function () {
        if (0 !== $('aside.dialog.show[class*="popup"]:not(.dialog\\\(scroll\\\)),aside.dialog.show[class*="alert"]:not([class*="persistent"]):not(.dialog\\\(scroll\\\)),aside.dialog.show[class*="sidebar"]:not([class*="persistent"]):not(.dialog\\\(scroll\\\))').length) {
            $('html, body').css('overflow', 'hidden');
        } else {
            $('html, body').css('overflow', '');
        }
    };

    const filterElements = function (group) {
        const $elementsInGroup = $('[data-group="' + group + '"][data-filters]'),
            isMethodOr = ($('[data-group="' + group + '"][data-filter][data-method="or"],select[data-group="' + group + '"][data-method="or"]:has(option[data-filter])').length > 0);

        let activeFilters = [];
        let activeExclude = [];
        let $elementsMustBeOpened = [];

        $('[data-group="' + group + '"][data-filter].open').each(function (index, element) {
            activeFilters = activeFilters.concat($(element).data('filter').split(','));
        });

        $('[data-group="' + group + '"][data-filter][data-exclude].open').each(function (index, element) {
            activeExclude = activeExclude.concat($(element).data('exclude').split(','));
        });

        $('select[data-group="' + group + '"]:has(option[data-filter])').each(function (index, element) {
            const optionSelectedFilter = $(element).children('option:selected').data('filter');

            if ('' !== optionSelectedFilter) {
                activeFilters = activeFilters.concat(optionSelectedFilter.split(','));
            }
        });

        $('select[data-group="' + group + '"]:has(option[data-filter][data-exclude])').each(function (index, element) {
            const optionSelectedExclude = $(element).children('option:selected').data('exclude');

            if (undefined !== optionSelectedExclude) {
                activeExclude = activeExclude.concat(optionSelectedExclude.split(','));
            }
        });

        activeFilters = activeFilters.unique();

        if (0 === activeFilters.length || (1 === activeFilters.length && 'all' === activeFilters[0])) {
            $('[data-group="' + group + '"][data-filter="all"]').addClass('open');

            $elementsMustBeOpened = $elementsInGroup;
        } else {
            $elementsInGroup.each(function (index, element) {
                const $element = $(element);
                let mustBeOpened = null;

                if (false === isMethodOr) {
                    mustBeOpened = true;

                    $.each(activeFilters, function (index, filter) {
                        if (-1 === $element.data('filters').split(',').indexOf(filter)) {
                            mustBeOpened = false;
                        }
                    });
                } else {
                    mustBeOpened = false;

                    $.each(activeFilters, function (index, filter) {
                        if (-1 !== $element.data('filters').split(',').indexOf(filter)) {
                            mustBeOpened = true;
                        }
                    });
                }

                $.each(activeExclude, function (index, exclude) {
                    if (-1 !== $element.data('filters').split(',').indexOf(exclude)) {
                        mustBeOpened = false;
                    }
                });

                if (true === mustBeOpened) {
                    $elementsMustBeOpened.push($element);
                }
            });
        }

        let hidePromises = [];
        let showPromises = [];

        const deferredFrameHide = function($element) {
            return $.Deferred(function(defer) {
                $element.on('hide', function() {
                    defer.resolve(true);
                });
                $element.frameHide();
            }).promise();
        };

        const deferredFrameShow = function($element) {
            return $.Deferred(function(defer) {
                $element.on('show', function() {
                    defer.resolve(true);
                });
                $element.frameShow();
            }).promise();
        };

        $elementsInGroup.each(function (index, element) {
            hidePromises.push(deferredFrameHide($(element)).then());
        });

        $.when.apply(null, hidePromises).done(function() {
            $.each($elementsMustBeOpened, function (index, $elementMustBeOpened) {
                if (false === ($elementMustBeOpened instanceof jQuery)) {
                    $elementMustBeOpened = $($elementMustBeOpened);
                }

                showPromises.push(deferredFrameShow($elementMustBeOpened).then());
            });

            $.when.apply(null, showPromises).done(function() {
                $(document).trigger('filter', group);
            });
        });
    };
   
    const filterSelect = function ($element) {
        const group = $element.data('group');

        if (false === $element.hasClass('multiple')) {
            $('[data-group="' + group + '"][data-filter].open').removeClass('open');
            $('input[type="checkbox"][data-group="' + group + '"][data-filter].open').prop('checked', false);
        } else {
            $('[data-group="' + group + '"][data-filter="all"].open').removeClass('open');
        }

        if ('' !== $element.val()) {
            $element.addClass('open');
        } else {
            $element.removeClass('open');
        }

        filterElements(group);
    };

    const sortElements = function (group) {
        const $elementsInGroup = $.extend({},  initialSortedGroup[group]);

        let activeSorts = [];

        $('[data-group="' + group + '"][data-sort].open, select[data-group="' + group + '"].open option[data-sort]:selected').sortByData('index').each(function (index, element) {
            const $element = $(element);

            activeSorts.push({ sort: $(element).data('sort'), direction: $(element).data('direction') ? $(element).data('direction') : 'asc'});
        });

        if (activeSorts.length > 0) {
            $('[data-group="' + group + '"][data-sorts]').parent().append($elementsInGroup.sortBy(activeSorts));
        } else {
            $('[data-group="' + group + '"][data-sorts]').parent().append(initialSortedGroup[group]);
        }
    };
   
    // Handler for click outside event for dialog element
    const dialogClickOutsideHandler = function (event) {
        const $containers = $('aside.dialog.show:not(.filter,.collapse,.tab,.dialog\\\(mandatory\\\))').sortByZindex().children('div:first-of-type'),
            $containersMandatory = $('aside.dialog.show.dialog\\\(mandatory\\\):not(.filter,.collapse,.tab)').children('div:first-of-type'),
            $container = $($containers[0]);
        if (false == $container.is(event.target) && 0 === $container.has(event.target).length && false == $containersMandatory.is(event.target) && 0 === $containersMandatory.has(event.target).length) {
            $container.parent('aside').frameHide();
            $container.find('[data-name]:not(.filter,.collapse,.tab)').frameHide();
        }
    };

    // Handler for click event for animated element
    const effectOnClickHandler = function (event) {
        let $element = $(event.target);

        if (false === $element.hasClass('click/effect')) {
            $element = $element.parents('.click\\\/effect');
        }

        if (false === $element.hasClass('animation')) {
            $element.addClass('animation');

            $element.on('animationend webkitAnimationEnd', function () {
                $element.removeClass('animation');
            });
        }

    };

    const scrollHandler = function (event) {
        const viewportHeight = window.innerHeight || document.documentElement.clientHeight,
            windowScrollTop = window.scrollY,
            scrollTopDifference = windowScrollTop - lastScrollTop;


        // animation loaded in fuction of scroll
        $('.scroll\\\/effect').each(function (index, element) {
            const $element = $(element),
                elementOffset = $element.offset();
            let scrollTop;

            if (false == $element.isVisible()) {
                $element.data('scroll', null);
                return;
            }

            $element.data('scroll', ($element.data('scroll') ?? 0) + scrollTopDifference);

            percent = $element.data('scroll') / viewportHeight;

            if (percent > 0) {
                $element.css('animation-delay',  - (parseInt(percent * 1000)) + 'ms');
            } else {
                $element.css('animation-delay',  - (parseInt((1 + percent) * 1000)) + 'ms');
            }
        });

        // video loaded in fuction of scroll
        $('video.scroll\\\/video').each(function (index, element) {
            const $element = $(element),
                elementOffset = $element.offset();

            let elementHeight = $element.data('height');

            if (false == $element.isVisible()) {
                return;
            }

            if (!elementHeight) {
                elementHeight = $element.get(0).getBoundingClientRect().height;
                $element.data('height', elementHeight);
            }

            if (elementOffset.top < (windowScrollTop + viewportHeight) && elementOffset.top + elementHeight > windowScrollTop) {
                element.pause();

                if (elementOffset.top < windowScrollTop) {
                    if (element.currentTime !== 0) {
                        element.currentTime = element.duration - 0.100;
                    }
                } else {
                    element.currentTime = (Math.round( 1000 * element.duration * (1 - (elementOffset.top - windowScrollTop) / viewportHeight)) / 1000)
                }
            }
        });

        // play video if in viewport
        $('video.scroll\\\/video\\\(play\\\)').each(function (index, element) {
            const $element = $(element),
                elementOffset = $element.offset();

            let elementHeight = $element.data('height');

            if (false == $element.isVisible()) {
                element.pause();
                element.currentTime = 0;
                return;
            }

            element.play();

            // if (!elementHeight) {
            //     elementHeight = $element.get(0).getBoundingClientRect().height;
            //     $element.data('height', elementHeight);
            // }

            // if (elementOffset.top < (windowScrollTop + viewportHeight) && elementOffset.top + elementHeight > windowScrollTop) {
            //     element.pause();

            //     if (elementOffset.top < windowScrollTop) {
            //         if (element.currentTime !== 0) {
            //             element.currentTime = element.duration - 0.100;
            //         }
            //     } else {
            //         element.currentTime = (Math.round( 1000 * element.duration * (1 - (elementOffset.top - windowScrollTop) / viewportHeight)) / 1000)
            //     }
            // }
        });


        $('.position\\\(sticky\\\)').each(function (index, element) {
            const $element = $(element),
                top = $element.data('initial-top');

            let limit = windowScrollTop;

             $(($element.is('header') ? '' : 'body > header,') + '.persistent\\\(top\\\) > div:first-of-type').each(function (index, elementTop) {
                const $elementTop = $(elementTop);

                limit += $elementTop.outerHeight();
             });

            if (top <= limit) {

                if (false === $element.hasClass('sticked')) {
                    const uuid = uuidv4(), 
                        div = $('<div data-uuid="' + uuid + '" />');

                    div.addClass($element[0].className.replace('position(sticky)', ''));
                    div.cssImportant('visibility', 'hidden');

                    div.data('uuid', uuid);
                    div.insertBefore($element);
                    $element.data('placeholder', uuid);
                }

                $('[data-uuid="' + $element.data('placeholder') + '"]')

                let top = 0;

                $(($element.is('header') ? '' : 'body > header,') + '.persistent\\\(top\\\) > div:first-of-type').each(function (index, elementTop) {
                    const $elementTop = $(elementTop);

                    if ($elementTop.isInViewport()) {
                        top += $elementTop.outerHeight(); 
                    }
                });

                $element
                    .cssImportant('position', 'fixed')
                    .cssImportant('top', top + 'px')
                    .addClass('sticked')
                ;

            } else {
                $element
                    .css('position', '')
                    .css('top', '')
                    .removeClass('sticked')
                ;

                $('[data-uuid="' + $element.data('placeholder') + '"]').remove();
            }
        });

        // infinite scroll
        $('.on\\\/infinitescroll').each(function (index, element) {
            const $element = $(element),
                elementEnd = $element.offset().top + $element.height();

            if (false == $element.isVisible()) {
                return;
            }

            if (windowScrollTop <= elementEnd && elementEnd <= (windowScrollTop + viewportHeight) && $element.data('loading') != '1') {
                $element.data('loading', '1');

                var data = $element.data('parameters') ? $element.data('parameters') : {},
                    page = $element.data('page') ? parseInt($element.data('page')) + 1 : 1,
                    pageAttribute = $element.data('page-attribute') ? $element.data('page-attribute') : 'page';

                data[pageAttribute] = page;
                $element.data('page', page);

                $.ajax({
                    url: $element.data('url'),
                    dataType: 'html',
                    method: $element.data('method') ? $element.data('method') : 'GET',
                    data: data,
                    context: $element,
                }).done(function (data) {
                    $(this).append($(data));
                    $.frameInitializeEvents();

                }).fail(function (error) {
                    console.error(error);
                }).always(function () {
                    $(this).data('loading', '0');
                });
            }
        });

        // play action on target
        playActionOnTarget();

        // add class aimation for element in viewport
        $('[class*="motion("]').each(function (index, element) {
            const $element = $(element);

            if ($element.isInViewport() && $element.parents('.slider').length === 0 && (false === $element.is('aside') || (true === $element.is('aside') && $element.hasClass('show')))) {
                $element
                    .addClass('animation')
                    .css('animation-play-state', '')
                ;
            }
        });

        // remove class animation for element not in viewport 
        $('.animation[class*="motion("].motion\\\(repeat\\\)').each(function (index, element) {
            const $element = $(element);

            if ((false === $element.isInViewport()) && $element.parents('.slider').length === 0 ) {
                $element.on('animationend webkitAnimationEnd', function (event) {
                    $element.removeClass('running');
                    $element.off('animationend webkitAnimationEnd');
                });

                $element.addClass('running');
                $element.removeClass('animation');
            }
        });

        // add class animation for  slide in viewport
        $('.slider.initialized > .slides >.slide.visible.active').each(function (index, element) {
            const $element = $(element);
            if (true === $element.isInViewport()) {
                $element.find('[class*="motion("]:not(.animation)').addClass('animation');    
            }
        });

        // // pause autoplay slider permanent if not in viewport
        // $('.slider.initialized[class*="autoplay("]').each(function (index, element) {
        //     const $element = $(element);

        //     if (false === $element.isInViewport()) {
        //         element.swiper.autoplay.stop();
        //     }
        // });

        // // pause slider permanent if not in viewport
        // $('.slider.initialized.permanent[class*="autoplay("]').each(function (index, element) {
        //     const $element = $(element);

        //     if (true === $element.isInViewport()) {
        //         element.swiper.autoplay.start();
        //     }
        // });

        lastScrollTop = windowScrollTop;
    };

    const addHash = function (hash) {
        const actualHash = window.location.hash.substr(1);

        if ('' !== actualHash) {
            var hashes = actualHash.split(',');

            if (-1 === hashes.indexOf(hash)) {
                hashes.push(hash);

                return '#' + hashes.join(',');
            }

            return '#' + actualHash;
        } else {
            return '#' + hash;
        }
    };

    const removeHash = function (hash) {
        const actualHash = window.location.hash;

        if ('' !== actualHash) {
            var hashes = actualHash.substr(1).split(',');

            if (-1 !== hashes.indexOf(hash)) {
                hashes.remove(hash);

                if (0 !== hashes.length) {
                    return '#' + hashes.join(',');
                }

                return '';
            }
        }

        return actualHash;
    };

    const humanTimeToMillisecond = function (humanTime) {
        if (-1 !== humanTime.indexOf('ms')) {
            return parseInt(humanTime);
        } else if (-1 !== humanTime.indexOf('s')) {
            return parseInt(humanTime) * 1000;
        }

        return false;
    };

    const pixelToInt = function (pixel) {
        if ('none' === pixel) {
            return 0;
        } else if (-1 !== pixel.indexOf('px')) {
            return parseInt(pixel);
        }

        return false;
    };

    const calculatePersistent = function () {

        // initaliser les varibles pour les différents padding, width et height à 0
        // incrementer les valeurs
        // appliquer le css si > 0

        
        $('.dialog.show.persistent\\\(top\\\)[class*="alert"]').each(function (index, element) {
            const $element = $(element),
                $elementDiv = $element.children('div:first-of-type'),
                $window = $(window);

            if ($element.isVisible()) {
                $('main').cssImportant('padding-top', $elementDiv.outerHeight(false) + 'px');
                $('header').cssImportant('top', $elementDiv.outerHeight(false) + 'px');
                $('.height\\\(full\\\)')
                    .cssImportant('height', ($window.height() - $elementDiv.outerHeight(false)) + 'px')
                    .cssImportant('min-height', ($window.height() - $elementDiv.outerHeight(false)) + 'px')
                ;
            } else {
                $('main').css('padding-top', '');
                $('header')
                    .css('top', '')
                ;
                $('.height\\\(full\\\)')
                    .css('height', '')
                    .css('min-height', '')
                ;
            }
        });

        $('.dialog.show.persistent\\\(bottom\\\)[class*="alert"]').each(function (index, element) {
            const $element = $(element),
                $elementDiv = $element.children('div:first-of-type'),
                $window = $(window);

            if ($element.isVisible()) {
                $('main').cssImportant('padding-bottom', $elementDiv.outerHeight(false) + 'px');
                $('footer').cssImportant('margin-bottom', $elementDiv.outerHeight(false) + 'px');
                $('.height\\\(full\\\)')
                    .cssImportant('height', ($window.height() - $elementDiv.outerHeight(false)) + 'px')
                    .cssImportant('min-height', ($window.height() - $elementDiv.outerHeight(false)) + 'px')
                ;
            } else {
                $('main').css('padding-bottom', '');
                $('footer').css('margin-bottom', '');
                $('header')
                    .css('height', '')
                    .css('min-height', '')
                ;
            }
        });

        $('.dialog.show.persistent\\\(left\\\)[class*="sidebar"]').each(function (index, element) {
            const $element = $(element),
                $elementDiv = $element.children('div:first-of-type'),
                $window = $(window);

            if ($element.isVisible()) {
                $('main').cssImportant('padding-left', $elementDiv.outerWidth(false) + 'px');
                $('header')
                    .cssImportant('width', ($window.width() - $elementDiv.outerWidth(false)) + 'px')
                    .cssImportant('right', '0px')
                ;
                $('.width\\\(full\\\)')
                    .cssImportant('width', ($window.width() - $elementDiv.outerWidth(false)) + 'px')
                    .cssImportant('min-width', ($window.width() - $elementDiv.outerWidth(false)) + 'px')
                ;
            } else {
                $('main').css('padding-left', '');
                $('header')
                    .css('width', '')
                    .css('right', '')
                ;
                $('.width\\\(full\\\)')
                    .css('width', '')
                    .css('min-width', '')
                ;
            }
        });

        $('.dialog.show.persistent\\\(right\\\)[class*="sidebar"]').each(function (index, element) {
            const $element = $(element),
                $elementDiv = $element.children('div:first-of-type'),
                $window = $(window);

            if ($element.isVisible()) {
                $('main').cssImportant('padding-right', $elementDiv.outerWidth(false) + 'px');
                $('header')
                    .cssImportant('width', ($window.width() - $elementDiv.outerWidth(false)) + 'px')
                    .cssImportant('left', '0px')
                ;
                $('.width\\\(full\\\)')
                    .cssImportant('width', ($window.width() - $elementDiv.outerWidth(false)) + 'px')
                    .cssImportant('min-width', ($window.width() - $elementDiv.outerWidth(false)) + 'px')
                ;
            } else {
                $('main').css('padding-right', '');
                $('header')
                    .css('width', '')
                    .css('left', '')
                ;
                $('.width\\\(full\\\)')
                    .css('width', '')
                    .css('min-width', '')
                ;
            }
        });
    };

    const playActionOnTarget = function () {
        const viewportHeight = window.innerHeight || document.documentElement.clientHeight,
            windowScrollTop = window.scrollY;

        // action on target

        $.each($('[data-target][data-method][data-action]').groupBy('target'), function (targetName, $elements) {
            const $target = $('[data-name="' + targetName + '"]'),
                targetRect = $target[0].getBoundingClientRect();

            let elementsToShow = [],
                elementsToHide = [],
                classesToAdd = [],
                classesToRemove = [];

            $.each($elements, function (index, $element) {
                const elementRect = $element[0].getBoundingClientRect(),
                    method = $element.data('method'),
                    classes = [],
                    showes = [];

                $.each($element.data('action').split(','), function (index, action) {
                    if (-1 !== action.indexOf('show:')) {
                        showes.push(action.replace('show:', ''));
                    } else {
                        classes.push(action);
                    }
                }); // $.each($element.data('action').split(',')

                switch (method) {
                    case 'surfaceInside':
                        if ((elementRect.height > 0 || elementRect.width > 0) && targetRect.top >= elementRect.top && targetRect.top + targetRect.height <= elementRect.top + elementRect.height) {
                            $.each(classes, function (index, classCss) {
                                if (-1 === classesToAdd.indexOf(classCss)) {
                                    classesToAdd.push(classCss);
                                }
                            });
                            $.each(showes, function (index, show) {
                                if (-1 === elementsToShow.indexOf(show)) {
                                    elementsToShow.push(show);
                                }
                            });
                        } else {
                            $.each(classes, function (index, classCss) {
                                if (-1 === classesToRemove.indexOf(classCss)) {
                                    classesToRemove.push(classCss);
                                }
                            });
                            $.each(showes, function (index, show) {
                                if (-1 === elementsToHide.indexOf(show)) {
                                    elementsToHide.push(show);
                                }
                            });
                        }
                        break;
                    case undefined:
                    case 'surfaceOutside':
                        if ((elementRect.height > 0 || elementRect.width > 0) && targetRect.top <=  elementRect.top + elementRect.height && targetRect.top + targetRect.height >= elementRect.top) {
                            $.each(classes, function (index, classCss) {
                                if (-1 === classesToAdd.indexOf(classCss)) {
                                    classesToAdd.push(classCss);
                                }
                            });
                        } else {
                            $.each(classes, function (index, classCss) {
                                if (-1 === classesToRemove.indexOf(classCss)) {
                                    classesToRemove.push(classCss);
                                }
                            });
                        }
                        break;
                    case 'viewportInside':
                        if ((elementRect.height > 0 || elementRect.width > 0) && elementRect.top >= 0 && elementRect.top + elementRect.height <= viewportHeight) {
                            $.each(classes, function (index, classCss) {
                                if (-1 === classesToAdd.indexOf(classCss)) {
                                    classesToAdd.push(classCss);
                                }
                            });
                        } else {
                            $.each(classes, function (index, classCss) {
                                if (-1 === classesToRemove.indexOf(classCss)) {
                                    classesToRemove.push(classCss);
                                }
                            });
                        }
                        break;
                    case 'viewportOutside':
                        if ((elementRect.height > 0 || elementRect.width > 0) && elementRect.top <= viewportHeight && elementRect.top + elementRect.height >= 0) {
                            $.each(classes, function (index, classCss) {
                                if (-1 === classesToAdd.indexOf(classCss)) {
                                    classesToAdd.push(classCss);
                                }
                            });
                        } else {
                            $.each(classes, function (index, classCss) {
                                if (-1 === classesToRemove.indexOf(classCss)) {
                                    classesToRemove.push(classCss);
                                }
                            });
                        }
                        break;
                    default:
                        return console.error('method ' + method + ' not allowed');
                } // switch (method)
            }); // $.each($elements, function (index, $element)

            classesToAdd = classesToAdd.unique();
            classesToRemove = classesToRemove.unique();

            $.each(classesToRemove, function (index, classToRemove) {
                if (-1 !== classesToAdd.indexOf(classToRemove)) {
                    classesToRemove.remove(classToRemove);
                }
            });

            $target.removeClass(classesToRemove.join(' ')).addClass(classesToAdd.join(' '));

            elementsToShow = elementsToShow.unique();
            elementsToHide = elementsToHide.unique();

            $.each(elementsToHide, function (index, elementToHide) {
                if (-1 !== elementsToShow.indexOf(elementToHide)) {
                    elementsToHide.remove(elementToHide);
                }
            });

            $.each(elementsToShow, function (index, elementToShow) {
                $elementToShow = $('[data-name="' + elementToShow + '"]');

                if (false === $elementToShow.hasClass('show') && true !== $elementToShow.data('show-target')) {
                    $elementToShow.frameShow();
                    $elementToShow.data('show-target', true);
                }
            });

            $.each(elementsToHide, function (index, elementToHide) {
                $elementToHide = $('[data-name="' + elementToHide + '"]');

                $elementToHide.removeData('show-target');
                $elementToHide.removeAttr('data-show-target');

                if (true === $elementToHide.hasClass('show')) {
                    $elementToHide.frameHide();
                }
            });
        });
    };

    /* 
        Jquery functions
    */

    $.playActionOnTarget = function() {
        playActionOnTarget();
    };

    $.frameInitialize = function () {
        $.frameInitializeSliders();
        $.frameInitializeVideos();

        $('aside.dialog.load\\\/dialog:not(aside.dialog.tab),.slide.load\\\/dialog').isNotParentsOf('aside').frameShow();

        // Scroll to anchor or open on load
        if (window.location.hash) {
            const hashes = window.location.hash.substr(1);

            $(hashes.split(',')).each(function (index, hash) {
                const $element = $('[data-name=' + hash + ']');

                if ($element.hasClass('tab')) {
                    const $otherTabsinGroups = $('.dialog.tab.show[data-group="' + $element.data('group') + '"]').not($element);

                    $otherTabsinGroups.removeClass('load\\\/dialog');
                    $element.addClass('load\\\/dialog');
                    $element.parents('section').frameShow();
                } else {
                    $element.frameShow();
                }
            });
        }

        // Initialize CSS Class auto
        $.frameClassAuto();

        // playActionOnTarget on targets animation end
        let targets = [],
            promises = [];

        $('[data-target]').each(function (index, opener) {
            const $opener = $(opener);

            targets.push($opener.data('target'));
        })

        targets = targets.unique();

        $('[data-name="' + targets.join('"],[data-name="') + '"]').each(function (index, element) {
            const $element = $(element);

            if ($element.is('[class*="motion("]') && $element.isInViewport()) {
               promises.push($.Deferred(function(defer) {
                    $element.on('animationend webkitAnimationEnd', function (event) {
                        defer.resolve(event);
                    });
                }).promise().then());
            }
        });

        $.when.apply(null, promises).done(function() {
            playActionOnTarget();
        });

        // Initale top for element sticky
        $('.position\\\(sticky\\\)').each(function (index, element) {
            const $element = $(element);

            if ($element.is('[class*="motion("]')) {
                $element.on('animationend webkitAnimationEnd', function (event) {
                    const elementRect = $element[0].getBoundingClientRect();
                    windowScrollTop = window.scrollY;

                    $element.data('initial-top', windowScrollTop + elementRect.top);
                });
            }
        });

         // Initialize Events
        $.frameInitializeEvents();

        // Filter on load
        $.frameFilter();

        // Filter on load
        $.frameSort();

        // initialize tab
        let tabGroups = {};
        let tabGroupsAutoplay = {};
        $('.dialog.tab[data-group]').each(function (index, tab) {
            const $tab = $(tab),
                group = $tab.data('group');

            if(0 !== $tab.parents('aside').length){
                return
            }

            if (typeof tabGroups[group] === 'undefined') {
                tabGroups[group] = [$tab];
            } else {
                tabGroups[group].push($tab);
            }

            if ($tab.classStart('autoplay(') || typeof tabGroupsAutoplay[group] !== 'undefined') {
                if (typeof tabGroupsAutoplay[group] === 'undefined') {
                    tabGroupsAutoplay[group] = [$tab];
                } else {
                    tabGroupsAutoplay[group].push($tab);
                }
            }
        });

        $.each(tabGroups, function (group, tabs) {
            let indexToOpen = 0;

            for (let i = 0; i < tabs.length; i++) {
                if (tabs[i].hasClass('load/dialog')) {
                    indexToOpen = i;
                    break;
                }
            }

            tabs[indexToOpen].frameShow();
        });

        $.each(tabGroupsAutoplay, function (group, tabs) {
            const $firstTab = $(tabs[0]),
                autoplayClass = $firstTab.classParameters('autoplay');

            if (autoplayClass.length > 0 && humanTimeToMillisecond(autoplayClass[0])) {
                const delay = humanTimeToMillisecond(autoplayClass[0]);

                window.setInterval(function (tabs) {
                    let lastIndex = 0,
                        tabToShowIndex = 0;

                    $.each(tabs, function (index, tab) {
                        if (tab.hasClass('show')) {
                            lastIndex = index;
                            tab.frameHide();
                        }
                    });

                    tabs[lastIndex + 1 == tabs.length ? 0 : lastIndex + 1].frameShow();
                }, delay, tabs);
            }

            return;
        });

        // Check if all data-name are unique
        const dataNames = [];

        $('[data-name]').each(function (index, element) {
            const dataName = $(element).data('name');

            if (-1 === dataNames.indexOf(dataName)) {
                dataNames.push(dataName);
            } else {
                console.error('Element with data-name="' + dataName + '" allready exists! Parameter data-name must be unique.');
            }
        });

        // Initale top for element sticky
        $('.position\\\(sticky\\\)').each(function (index, element) {
            const $element = $(element);

            if (false === $element.is('[class*="motion("]')) {
                 elementRect = $element[0].getBoundingClientRect();
                windowScrollTop = window.scrollY;

                $element.data('initial-top', windowScrollTop + elementRect.top);
            }
        });
    };

    $.sortSliders = function () {
        const sortByTarget = function (a, b) {
            const $a = $(a),
                $b = $(b),
                dataNameA = $a.data('name'),
                dataTargetA = $a.data('target'),
                dataNameB = $b.data('name'),
                dataTargetB = $b.data('target');

            if (dataTargetA == undefined) {
                return 1;
            } else if (dataNameA == undefined) {
                return -1
            } else if (dataTargetA == dataNameB) {
                return 1;
            } else  {
                return 0;
            }
        };

        return $('.slider').sort(sortByTarget);
    };

    $.frameInitializeSliders = function () {
        $.sortSliders().each(function (index, element) {
            $(element).slider();
        });
    };

    $.frameInitializeVideos = function () {
        $('video').each(function (index, element) {
            const $element = $(element);

            $element.video();
        });
    };

    // Stop event handler et add evet handler
    $.frameInitializeEvents = function () {
        /* 
        *    Delete old events
        */
        $(document).off('.frame');
        $('*').off('.frame');

        /* 
        *    Show/Hide functions
        */

        // Show on click
        $('[data-' + showAction + ']').on('click.frame', function (event) {
            const $self = $(this);
            
            $($self.data(showAction).toString().split(',')).each(function (index, dataShow) {
                const $element = $('[data-name="' + dataShow + '"]'),
                    open = $self.hasClass('open');

                if (open && $element.hasClass('collapse')) {
                    $element.frameHide();
                    event.stopPropagation();
                } else {
                    if ($element.hasClass('tab')) {
                        const $otherTabsinGroups = $('.dialog.tab.show[data-group="' + $element.data('group') + '"]').not($element);

                        $otherTabsinGroups.on('hide', function (event) {
                            $otherTabsinGroups.off(event);
                            $element.frameShow();
                        });

                        $otherTabsinGroups.frameHide();
                    } else if ($element.hasClass('collapse')) {
                        const $otherCollapsesInGroup = $('.dialog.collapse.show[data-group="' + $element.data('group') + '"]').not($element);
                        
                        if ($otherCollapsesInGroup.length > 0) {
                            $otherCollapsesInGroup.on('hide', function (event) {
                                $otherCollapsesInGroup.off(event);
                                $element.frameShow();
                            });

                            $otherCollapsesInGroup.frameHide();
                        } else {
                            if (false === $element.isShow()) {
                                $element.frameShow();
                            } else {
                                $element.frameHide();
                            }
                            
                        }
                    }
                    else {
                        $element.frameShow();
                    }
                    
                    event.stopPropagation();
                }
            });
        });

        // Hide on Click
        $('[data-' + hideAction + ']').on('click.frame', function (event) {
            $($(this).data(hideAction).toString().split(',')).each(function (index, dataHide) {
                $('[data-name="' + dataHide + '"]').frameHide();
            });

            event.stopPropagation();
        });

        // Show on mouseover
        $('[data-' + showAction + '].hover\\\/dialog').on('mouseover.frame, touchstart.frame', function (event) {
            const $self = $(this);

            if ($self.data(showAction)) {
                $($self.data(showAction).toString().split(',')).each(function (index, dataShow) {
                    const $element = $('[data-name="' + dataShow + '"]');

                    if (false === $element.isShow()) {
                        $element.frameShow();
                    }
                });
            }

            if ($self.data(hideAction)) {
                $($self.data(hideAction).toString().split(',')).each(function (index, dataHide) {
                    const $element = $('[data-name="' + dataHide + '"]');

                    if (true === $element.isShow()) {
                        $element.frameHide();
                    }
                });
            }

            event.stopPropagation();

        // Hide on mouseleave
        }).on('mouseleave.frame, touchleave.frame', function (event) {
            const $self = $(this);

            if ($self.data(showAction)) {
                $($self.data(showAction).toString().split(',')).each(function (index, dataShow) {
                    const $element = $('[data-name="' + dataShow + '"]'),
                        elementOffset = $element.offset();

                    if (true === $element.isShow()) {
                        $element.frameHide();
                    }
                });
            }

            if ($self.data(hideAction)) {
                $($self.data(hideAction).toString().split(',')).each(function (index, dataHide) {
                    const $element = $('[data-name="' + dataHide + '"]');

                    if (false === $element.isShow()) {
                        $element.frameShow();
                    }
                });
            }

            event.stopPropagation();
        });

        // Scroll on click or mouse over
        $('[data-' + scrollAction + ']').on('click.frame', function (event) {
            const $self = $(this);

            if ($self.data(scrollAction)) {
                $($self.data(scrollAction).toString().split(',')).each(function (index, dataShow) {
                    const $element = $('[data-name="' + dataShow + '"]'),
                        duration = $self.data(scrollActionDuration) ?? 400;

                    $element.frameScrollTo($self.hasClass('scroll\/noanimation'), duration);
                });
            }
        });

        // Scroll on mouse over
        $('[data-' + scrollAction + '].hover\\\/dialog').on('mouseover.frame, touchstart.frame, touchcancel.frame', function (event) {
            const $self = $(this);

            if ($self.data(scrollAction)) {
                $($self.data(scrollAction).toString().split(',')).each(function (index, dataShow) {
                    const $element = $('[data-name="' + dataShow + '"]'),
                        duration = $self.data('scroll-duration') ?? 400;

                    $element.frameScrollTo($self.hasClass('scroll\/noanimation'), duration);
                });
            }
        });
        
        // Filter for select on load and on change
        $('select[data-group]:has(option[data-filter])').on('change.frame', function (event) {
            filterSelect($(this));
        })

        // Filter when click
        $('[data-filter]').not('option').on('click.frame', function (event) {
            const $self = $(this),
                group = $self.data('group');

            if ('all' === $self.data('filter')) {
                $('[data-group="' + group + '"][data-filter].open').removeClass('open');
                $('input[type="checkbox"][data-group="' + group + '"][data-filter].open').prop('checked', false);
                $('select[data-group="' + group + '"]').val('').removeClass('open');
                $self.addClass('open');
            } else {
                $('[data-group="' + group + '"][data-filter="all"].open').removeClass("open");

                if (true === $self.hasClass('multiple')) {
                    $self.toggleClass('open');
                } else {
                    $('input[type="checkbox"][data-group="' + group + '"][data-filter].open:not(.multiple)').not($self).prop('checked', false);
                    $('[data-group="' + group + '"][data-filter].open:not(.multiple)').not($self).removeClass('open');
                    $('select[data-group="' + group + '"]:has(option[data-filter]):not(.multiple)').val('').removeClass('open');
                    $self.toggleClass('open');
                }
            }

            filterElements(group);
        });

        // Sort for select on load and on change
        $('select[data-group]:has(option[data-sort])').on('change.frame', function (event) {
            const $self = $(this),
                group = $self.data('group'),
                $selectedOption = $self.find('option[data-sort]:selected'),
                $otherOptions = $self.find('option[data-sort]:selected');

            if (true === $self.hasClass('multiple')) {
                if ('' !== $self.val()) {
                    if (false === $self.hasClass('open')) {
                        const $lastOpenedSorts = $('[data-sort][data-group="' + group + '"].open, select[data-group="' + group + '"] option[data-sort]:selected').not($self).not($self.find('option:selected')).sortByData('index').last();
                        let lastIndex = 0;

                        if ($lastOpenedSorts.length > 0) {
                            lastIndex = $lastOpenedSorts.data('index');
                        }

                        $self.find('option:selected').data('index', parseInt(lastIndex) + 1);
                    } else {
                        let lastIndex = $self.find('option').not(':selected').sortByData('index').last().data('index');

                        $self.find('option:selected').data('index', parseInt(lastIndex));

                        $self.find('option').not(':selected').removeData('index');
                        $self.find('option').not(':selected').removeAttr('data-index');
                    }
                }
            } else {
                $('[data-group="' + group + '"][data-sort].open').not($self).removeClass('open');
                $('select[data-group="' + group + '"]:has(option[data-sort])').not($self).val('').removeClass('open');
            }

            if ('' !== $self.val()) {
                $self.addClass('open');
            } else {
                $self.find('option').removeData('index');
                $self.find('option').removeAttr('data-index');
                $self.removeClass('open');
            }

            sortElements(group);
        })

        // Sort when click
        $('[data-sort]').not('option').on('click.frame', function (event) {
            const $self = $(this),
                group = $self.data('group'),
                sort = $self.data('sort'),
                $sibling = $('[data-sort="' + sort + '"][data-group="' + group + '"].open').not($self);


            if (true === $self.hasClass('multiple')) {
                if (false === $self.hasClass('open')) {
                    if ($sibling.length > 0) {
                        $sibling.removeClass('open');
                        $self.data('index', $sibling.data('index'));
                    } else {
                        const $lastOpenedSorts = $('[data-sort][data-group="' + group + '"].open').not($self).sortByData('index').last();
                        let lastIndex = 0;

                        if ($lastOpenedSorts.length > 0) {
                            lastIndex = $lastOpenedSorts.data('index');
                        }

                        $self.data('index', parseInt(lastIndex) + 1);
                    }
                } else {
                    $self.removeData('index');
                    $self.removeAttr('data-index');
                }

                $self.toggleClass('open');
            } else {
                $('[data-group="' + group + '"][data-sort].open').removeClass('open');
                $('select[data-group="' + group + '"]:has(option[data-sort])').val('').removeClass('open');
                $self.addClass('open');
            }

            sortElements(group);
        });

        $('.search[data-group]').on('keyup', function (event) {
            const $search = $(this),
                query = $search.val(),
                group = $search.data('group'),
                $elements = $('aside[data-group="' + group + '"]');

            $elements.each(function(index, element) {
                const $element = $(element);

                if (-1 === $element.text().toLowerCase().indexOf(query.toLowerCase())) {
                    $element.addClass('hide');
                } else {
                    $element.removeClass('hide');
                }
            });
        });

        // Actions on resize orientationchange        
        $(window).on('resize.frame orientationchange.frame', function () {
            calculatePersistent();

            $('.position\\\(sticky\\\)').each(function (index, element) {
                const $element = $(element),
                    elementRect = $element[0].getBoundingClientRect();
                    windowScrollTop = window.scrollY;

                if ($element.hasClass('sticked')) {
                    const $placeholder = $('[data-uuid="' + $element.data('placeholder') + '"]')
                        placeholderRect = $placeholder[0].getBoundingClientRect();

                    $element.data('initial-top', windowScrollTop + placeholderRect.top);

                } else {
                    $element.data('initial-top', windowScrollTop + elementRect.top);
                }
            });

            $('aside.dialog[class*=" dropdown"],.dialog[class^="dropdown"]').frameHide();
        });


        // Actions on scroll
        $('[data-name] > div:first-of-type').on('scroll.frame', scrollHandler);

        $(window).on('load.frame scroll.frame resize.frame orientationchange.frame', scrollHandler);

        // Animate Element on mousemove or touchmove
        $('[class*="effect("].move\\\/effect')
            .on('mousemove.frame' , function (event) {
                var $element = $(event.target),
                    x,
                    y,
                    xPercent,
                    yPercent;

                if ($element.hasClass('move/effect')) {
                    x = event.offsetX;
                    y = event.offsetY;
                } else {
                    var position = $element.position();

                    $element = $element.parents('[class*="effect("].move\\\/effect');

                    x = parseInt(position.left + event.offsetX);
                    y = parseInt(position.top + event.offsetY);
                }

                xPercent = parseInt(x / $element.width() * 1000);
                yPercent = parseInt(y / $element.height() * 1000);

                $element.css('animation-delay',  (-xPercent) + 'ms,' + (-yPercent) + 'ms');
            }).on('touchmove.frame', function (event) {
                var $element = $(event.target);

                if (false === $element.hasClass('move/effect')) {
                    $element = $element.parents('[class*="effect("].move\\\/effect');  
                }

                const offset = $element.offset(),
                    xPercent = 1000 - parseInt((event.pageX - offset.left) / $element.width() * 1000),
                    yPercent = 1000 - parseInt((event.pageY - offset.top) / $element.height() * 1000);

                $element.css('animation-delay',  (-xPercent) + 'ms,' + (-yPercent) + 'ms');

                event.preventDefault();
            })
        ;

        // Animate Element on click
        $('.click\\\/effect').on('click.frame' , effectOnClickHandler);
        $('.hover\\\/effect').on('mouseover.frame', function (event) {
            let $element = $(event.target);

            if (false === $element.hasClass('hover/effect')) {
                $element = $element.parents('.hover\\\/effect');
            }

            if (false === $element.hasClass('animation')) {
                $element.addClass('animation');

                $element.on('animationend webkitAnimationEnd', function () {
                    $element.removeClass('animation');
                });
            }
        });

        // Control slider
        $('.controls[data-target] .previous').on('click.frame', function (event) {
            const $opener = $(event.target).parents('.controls[data-target]'),
                target = $opener.data('target'),
                $slider = $('[data-name="' + target + '"]');

            if ($slider.length === 0) {
                return console.error('Element with data-name "' + target + '" doesn\'t exists !');
            }

            if (typeof $slider[0].swiper === 'undefined') {
                return console.error('Element with data-name "' + target + '" isn\'t a slider !');   
            }

            $slider[0].swiper.slidePrev();

        });

         $('.controls[data-target] .next').on('click.frame', function (event) {
            const $opener = $(event.target).parents('.controls[data-target]'),
                target = $opener.data('target'),
                $slider = $('[data-name="' + target + '"]');

            if ($slider.length === 0) {
                return console.error('Element with data-name "' + target + '" doesn\'t exists !');
            }

            if (typeof $slider[0].swiper === 'undefined') {
                return console.error('Element with data-name "' + target + '" isn\'t a slider !');   
            }

            $slider[0].swiper.slideNext();

        });

        $('.zoomAuto,[class*=" zoomAuto("],[class^="zoomAuto("]').on('dblclick.frame' , function (event) {
            const $element = $(event.target)
                coefficient = 2;

            if (false === $element.hasClass('zoomAuto') && false === $element.classStart('zoomAuto(')) {
                return;
            }

            let zoom = parseInt($element.data('zoom')),
                maxZoom = 2;

            if ($element.classStart('zoomAuto(')) {
                maxZoom = parseInt($element.classParameters('zoomAuto')[0]);
            }

            $element.backgroundSize(function (dimensions) {
                $element.backgroundPosition(function (positions) {
                    if (!$element.data('zoom') || zoom === 0) {
                        zoom = 1;
                        $element.addClass('zooming');
                    } else if (zoom == maxZoom) {
                        $element
                            .css({
                                'background-position': '',
                                'background-size': ''
                            })
                            .data('zoom', 0)
                            .removeClass('zooming')
                        ;
                        return;
                    } else {
                        zoom = zoom + 1;
                    }

                    let backgroundPositionX = (dimensions.width * coefficient - $element.width()) / -2 + ($element.width() / 2 - event.offsetX) * coefficient + (positions.x + (dimensions.width - $element.width()) / 2) * coefficient;
                    let backgroundPositionY = (dimensions.height * coefficient - $element.height()) / -2 + ($element.height() / 2 - event.offsetY) * coefficient + (positions.y + (dimensions.height - $element.height()) / 2) * coefficient;

                    if (backgroundPositionX > 0) {
                        backgroundPositionX = 0;
                    } else if (backgroundPositionX < - (dimensions.width * coefficient - $element.width())) {
                        backgroundPositionX = - (dimensions.width * coefficient - $element.width());
                    }

                    if (backgroundPositionY > 0) {
                        backgroundPositionY = 0;
                    } else if (backgroundPositionY < - (dimensions.height * coefficient - $element.height())) {
                        backgroundPositionY = - (dimensions.height * coefficient - $element.height());
                    }

                    $element
                        .css({
                            'background-size': (dimensions.width * coefficient) + 'px ' + (dimensions.height * coefficient) + 'px',
                            'background-position-x': backgroundPositionX,
                            'background-position-y': backgroundPositionY
                        })
                        .data('zoom', zoom)
                    ;
                });
            }, true);
        }).on('mousedown', function (event) {
            const $element = $(event.target);

            if (false === $element.hasClass('zooming')) {
                return
            }

            const initialX = event.offsetX,
                initialY = event.offsetY,
                initialBackgroundPositionX = parseInt($element.css('background-position-x')),
                initialBackgroundPositionY = parseInt($element.css('background-position-y'));

            $element.on('mouseup', function (event) {
                $element
                    .off('mouseup')
                    .off('mousemove')
                    .off('mouseenter')
                ;
            });

            $element.on('mouseout', function (event) {
                $('body').on('mouseup', function (event) {

                    $element
                        .off('mouseup')
                        .off('mousemove')
                        .off('mouseenter')
                    ;
                    $('body').off('mouseup');
                });
            });

            $element.on('mouseenter', function (event) {
                $('body').off('mouseup');
            });

            $element.on('mousemove', function (event) {
                const x = event.offsetX,
                    y = event.offsetY,
                    dimension = $element.css('background-size').replace(/px/g, '').split(' '),
                    width = parseInt(dimension[0]),
                    height = parseInt(dimension[1]);

                let backgroundPositionX = initialBackgroundPositionX + (x - initialX),
                    backgroundPositionY = initialBackgroundPositionY + (y - initialY);

                if (backgroundPositionX > 0 || backgroundPositionX < - (width - $element.width())) {
                    backgroundPositionX = false;
                }
                
                if (backgroundPositionY > 0 || backgroundPositionY < - (height - $element.height())) {
                    backgroundPositionY = false;
                }

                if (false === backgroundPositionX && false === backgroundPositionY) {
                    return
                } else if (false === backgroundPositionX) {
                    $element.css('background-position-y', backgroundPositionY);
                } else if (false === backgroundPositionY) {
                    $element.css('background-position-x', backgroundPositionX);
                } else {
                    $element.css('background-position', backgroundPositionX + 'px ' + backgroundPositionY + 'px');
                }
            });
        });

        var evCache = new Array();
        var prevDiff = -1;

        function pointerdown_handler(ev) {
         // L'événement pointerdown signale le début d'une interraction de toucher.
         // L'événement est mis en cache pour prendre en charge les gestes à 2 doigts
         evCache.push(ev);

        }


        function pointermove_handler(ev) {
             // Cette fonction implémente la détection du mouvement horizontal pincer/zoomer.
             //
             // Si la distance entre les deux pointeurs augmente (zoomer), 
             // l'arrière-plan de l'élément cible est changé en "pink" et si la 
             // distance diminue (dezoomer), la couleur est changée en "lightblue".
             //
             // Cette fonctionne définie la bordure de l'élément cible à "dashed" pour indiquer
             // visuellement que la cible du pointeur a reçu un événement de déplacement.

             ev.target.style.border = "dashed";

             // Trouve le pointeur en cours dans le cache et le met à jour avec cet événement
             for (var i = 0; i < evCache.length; i++) {
               if (ev.pointerId == evCache[i].pointerId) {
                  evCache[i] = ev;
                  break;
               }
             }

             // Si deux pointeurs sont utilisés, vérifie le geste de pincement
             if (evCache.length == 2) {
               // Calcule la distance entre les deux pointeurs
               var curDiff = Math.abs(evCache[0].clientX - evCache[1].clientX);

               if (prevDiff > 0) {
                 if (curDiff > prevDiff) {
                   // La distance entre les deux pointeurs a augmenté

                   ev.target.style.background = "pink";
                 }
                 if (curDiff < prevDiff) {
                   // La distance entre les deux pointeurs a diminué

                   ev.target.style.background = "lightblue";
                 }
               }

               // Met en cache la distance pour les événements suivants
               prevDiff = curDiff;
             }
            }
        function pointerup_handler(ev) {

          // Retire ce pointeur du cache et rétablit l'arrière-plan et
          // et bordure de la cible
          remove_event(ev);
          ev.target.style.background = "white";
          ev.target.style.border = "1px solid black";
         
          // Si le nombre de pointeurs restant est inférieur à deux, remet à zéro la différence
          if (evCache.length < 2) prevDiff = -1;
        }

        function remove_event(ev) {
         // Supprime l'événement du cache
         for (var i = 0; i < evCache.length; i++) {
           if (evCache[i].pointerId == ev.pointerId) {
             evCache.splice(i, 1);
             break;
           }
         }
        }


        $('[class*=" zoomAutoTouch("],[class^="zoomAutoTouch("]')
            .on('pointerdown', pointerdown_handler)
            .on('pointermove', pointermove_handler)
            .on('pointerup', pointerup_handler)
            .on('pointercancel', pointerup_handler)
            .on('pointerout', pointerup_handler)
            .on('pointerleave', pointerup_handler)
        ;

        // Animate Element on gyro
        $('[data-permission]').click(function (){
            function roundValue(value) { value = parseInt(value); if (1 == (value % 2)) value = value + 1; return value; }

            DeviceOrientationEvent.requestPermission()
            .then(response => {
                if (response == 'granted') {
                    gyro.stopTracking();
                    gyro.calibrate();

                    let lastTimestamp = null;
                    const $elements = $('[class*="effect("].motion\\\/effect');

                    const refresh = function (timestamp) {
                        if (lastTimestamp !== null) {
                            const delay = timestamp - lastTimestamp;
                            var xPercent, yPercent;
                            
                            if (delay > 10) {
                                const o = gyro.getOrientation(),
                                    factor = 3;

                                lastTimestamp = timestamp;

                                xPercent = roundValue(o.gamma + 50);
                                yPercent = roundValue(o.beta + 50);
                                zPercent = roundValue(o.gamma + 50);

                                $elements.css('animation-delay',  (-xPercent * 10 + 250) + 'ms,' + (-yPercent * 10 + 250) + 'ms,' + (-zPercent * 10 + 250) + 'ms');
                            }
                        } else {
                            lastTimestamp = timestamp;
                        }

                        window.requestAnimationFrame(refresh);
                    };

                    window.requestAnimationFrame(refresh);
                }
            })
            .catch(console.error);
        });

        $('[data-file]').each(function (index, element) {
            const $element = $(element),
                fileElementId = $element.data('file'),
                $file = $('#' + fileElementId),
                $form = $element.parents('form');

            $element.data('message', $element.html());

            $form.on('reset', function (event) {
                $element.html($element.data('message'));
            });
            
            if ($file.length !== 0) {
                $file.on('change', function (event) {
                    let filenames = [];

                    $.each($file.get(0).files, function (index, file) {
                        filenames.push(file.name);
                    });

                    $element.html(filenames.join(', '));
                });
            }
        });
    };

    $.frameFilter = function () {
        let groups = [];

        $('[data-group]').each(function (index, element) {
            const $element = $(element);

            groups.push($element.data('group'));
        })

        groups = groups.unique();

        $.each(groups, function (index, group) {
            filterElements(group);
        });
    };

    $.frameSort = function () {
        let groups = [];

        $('[data-group][data-sorts]').each(function (index, element) {
            const $element = $(element);

            groups.push($element.data('group'));
        })

        groups = groups.unique();

        $.each(groups, function (index, group) {
            initialSortedGroup[group] = $('[data-group="' + group + '"][data-sorts]');
            sortElements(group);
        });
    };

    $.frameClassAuto = function () {
        $('.delayAuto').each(function (index, elementDelayAuto) {
            $(elementDelayAuto).find('[class*="motion("]').not('aside').notParents('aside').each(function (index, element) {
                const $element = $(element);

                if ($element.notClassStart('delay(')) {
                    $element.css('animation-delay', (index * 250) + 'ms');    
                }
            });
        });

        $('[class*=" delayAuto("],[class^="delayAuto("]').not('aside').notParents('aside').each(function (index, elementDelayAuto) {
            const $elementDelayAuto = $(elementDelayAuto)
                delay = humanTimeToMillisecond($elementDelayAuto.classParameters('delayAuto')[0]);

            $(elementDelayAuto).find('[class*="motion("]').each(function (index, element) {
                const $element = $(element);

                if ($element.notClassStart('delay(')) {
                    $element.css('animation-delay', (index * delay) + 'ms');
                }
            });
        });

        $('[class*=" delay("],[class^="delay("]').each(function (index, element) {
            $(element).valueFromClassParametters('delay', 'animation-delay');
        });

        $('[class*=" speed("],[class^="speed("]').each(function (index, element) {
            $(element).valueFromClassParametters('speed', 'animation-duration');
        });

        $('[class*=" widthAuto("],[class^="widthAuto("]').each(function (index, element) {
            $(element).valueFromClassParametters('widthAuto', 'width');
        });

        $('[class*=" widthMaxAuto("],[class^="widthMaxAuto("]').each(function (index, element) {
            $(element).valueFromClassParametters('widthMaxAuto', 'max-width');
        });

        $('[class*=" widthMinAuto("],[class^="widthMinAuto("]').each(function (index, element) {
            $(element).valueFromClassParametters('widthMinAuto', 'min-width');
        });

        $('[class*=" heightAuto("],[class^="heightAuto("]').each(function (index, element) {
            $(element).valueFromClassParametters('heightAuto', 'height');
        });

        $('[class*=" heightMaxAuto("],[class^="heightMaxAuto("]').each(function (index, element) {
            $(element).valueFromClassParametters('heightMaxAuto', 'max-height');
        });

        $('[class*=" heightMinAuto("],[class^="heightMinAuto("]').each(function (index, element) {
            $(element).valueFromClassParametters('heightMinAuto', 'min-height');
        });

        $('[class*=" paddingAuto("],[class^="paddingAuto("]').each(function (index, element) {
            $(element).valueFromClassParametters('paddingAuto', 'padding');
        });

        $('[class*=" paddingTopAuto("],[class^="paddingTopAuto("]').each(function (index, element) {
            $(element).valueFromClassParametters('paddingTopAuto', 'padding-top');
        });

        $('[class*=" paddingBottomAuto("],[class^="paddingBottomAuto("]').each(function (index, element) {
            $(element).valueFromClassParametters('paddingBottomAuto', 'padding-bottom');
        });

        $('[class*=" paddingLeftAuto("],[class^="paddingLeftAuto("]').each(function (index, element) {
            $(element).valueFromClassParametters('paddingLeftAuto', 'padding-left');
        });

        $('[class*=" paddingRightAuto("],[class^="paddingRightAuto("]').each(function (index, element) {
            $(element).valueFromClassParametters('paddingRightAuto', 'padding-right');
        });

        $('[class*=" paddingTopBottomAuto("],[class^="paddingTopBottomAuto("]').each(function (index, element) {
            $(element).valueFromClassParametters('paddingTopBottomAuto', 'padding-top');
            $(element).valueFromClassParametters('paddingTopBottomAuto', 'padding-bottom');
        });

        $('[class*=" paddingLeftRightAuto("],[class^="paddingLeftRightAuto("]').each(function (index, element) {
            $(element).valueFromClassParametters('paddingLeftRightAuto', 'padding-left');
            $(element).valueFromClassParametters('paddingLeftRightAuto', 'padding-right');
        });

        $('[class*=" marginAuto("],[class^="marginAuto("]').each(function (index, element) {
            $(element).valueFromClassParametters('marginAuto', 'margin');
        });

        $('[class*=" marginTopAuto("],[class^="marginTopAuto("]').each(function (index, element) {
            $(element).valueFromClassParametters('marginTopAuto', 'margin-top');
        });

        $('[class*=" marginBottomAuto("],[class^="marginBottomAuto("]').each(function (index, element) {
            $(element).valueFromClassParametters('marginBottomAuto', 'margin-bottom');
        });

        $('[class*=" marginLeftAuto("],[class^="marginLeftAuto("]').each(function (index, element) {
            $(element).valueFromClassParametters('marginLeftAuto', 'margin-left');
        });

        $('[class*=" marginRightAuto("],[class^="marginRightAuto("]').each(function (index, element) {
            $(element).valueFromClassParametters('marginRightAuto', 'margin-right');
        });

        $('[class*=" marginTopBottomAuto("],[class^="marginTopBottomAuto("]').each(function (index, element) {
            $(element).valueFromClassParametters('marginTopBottomAuto', 'margin-top');
            $(element).valueFromClassParametters('marginTopBottomAuto', 'margin-bottom');
        });

        $('[class*=" marginLeftRightAuto("],[class^="marginLeftRightAuto("]').each(function (index, element) {
            $(element).valueFromClassParametters('marginLeftRightAuto', 'margin-left');
            $(element).valueFromClassParametters('marginLeftRightAuto', 'margin-right');
        });

        $('[class*=" distanceTopAuto("],[class^="distanceTopAuto("]').each(function (index, element) {
            $(element).valueFromClassParametters('distanceTopAuto', 'top');
        });

        $('[class*=" distanceBottomAuto("],[class^="distanceBottomAuto("]').each(function (index, element) {
            $(element).valueFromClassParametters('distanceBottomAuto', 'bottom');
        });

        $('[class*=" distanceLeftAuto("],[class^="distanceLeftAuto("]').each(function (index, element) {
            $(element).valueFromClassParametters('distanceLeftAuto', 'left');
        });

        $('[class*=" distanceRightAuto("],[class^="distanceRightAuto("]').each(function (index, element) {
            $(element).valueFromClassParametters('distanceRightAuto', 'right');
        });

        $('[class*=" distanceTopLeftAuto("],[class^="distanceTopLeftAuto("]').each(function (index, element) {
            $(element).valueFromClassParametters('distanceTopLeftAuto', 'top');
            $(element).valueFromClassParametters('distanceTopLeftAuto', 'left');
        });

        $('[class*=" distanceTopRightAuto("],[class^="distanceTopRightAuto("]').each(function (index, element) {
            $(element).valueFromClassParametters('distanceTopRightAuto', 'top');
            $(element).valueFromClassParametters('distanceTopRightAuto', 'right');
        });

        $('[class*=" distanceBottomLeftAuto("],[class^="distanceBottomLeftAuto("]').each(function (index, element) {
            $(element).valueFromClassParametters('distanceBottomLeftAuto', 'bottom');
            $(element).valueFromClassParametters('distanceBottomLeftAuto', 'left');
        });

        $('[class*=" distanceBottomRightAuto("],[class^="distanceBottomRightAuto("]').each(function (index, element) {
            $(element).valueFromClassParametters('distanceBottomRightAuto', 'bottom');
            $(element).valueFromClassParametters('distanceBottomRightAuto', 'right');
        });

        $('[class*=" sizeAuto("],[class^="sizeAuto("]').each(function (index, element) {
            const $element = $(element),
                sizeAuto = $element.classParameters('sizeAuto')[0],
                value = parseInt(sizeAuto);

            let unity,
                padding = (value - value * 0.05) / 2;

            if (-1 !== sizeAuto.indexOf('%')) {
                unity = '%';
            } else if (-1 !== sizeAuto.indexOf('vw')) {
                unity = 'vw';
            } else {
                return;
            }

            $element
                .cssImportant('padding-left', padding + unity)
                .cssImportant('padding-right', padding + unity)
            ;
        });
    };

    /* 
        Jquery element functions
    */
   
    $.fn.sendWithAjax = function (settings) {
        const $form = this;
        let csrfEnabled = false;

        if (undefined === settings) {
            settings = {};
        }

        if (false === $form.is('form')) {
            console.error('This element isn\'t a form.')
            return false;
        }

        if (typeof window.csrfTokenName !== 'undefined' && typeof window.csrfTokenValue !== 'undefined') {
            let $csrfInput = $form.find('[name="' + window.csrfTokenName + '"]');

            csrfEnabled = true;

            if (0 !== $csrfInput.length) {
                $csrfInput.val(window.csrfTokenValue);    
            } else {
                $csrfInput = $('<input type="hidden" name="' + window.csrfTokenName + '" value="' + window.csrfTokenValue + '"/>');
                $form.append($csrfInput);
            }
        }

        let defaultSettings = {
            method: $form.attr('method'),
            url: $form.attr('action'),
            data: new FormData($form.get(0)),
            processData: false,
            contentType: false,
            success: function(data) {
                alert(data);
            },
            error: function(jqXHR, textStatus, errorThrown ) {
            },
        };

        let usedSettings = $.extend({}, defaultSettings, settings);

        $.ajax(usedSettings)
            .done(function( data, textStatus, jqXHR ) {
                if (true === csrfEnabled && null !== jqXHR.getResponseHeader('Csrf')) {
                    window.csrfTokenValue = jqXHR.getResponseHeader('Csrf');    
                }
            })
            .fail(function (jqXHR, textStatus, errorThrown) {
                if (true === csrfEnabled && null !== jqXHR.getResponseHeader('Csrf')) {
                    window.csrfTokenValue = jqXHR.getResponseHeader('Csrf');    
                }

                if (302 === jqXHR.status) {
                    $.ajax({
                        url: jqXHR.getResponseHeader('X-Redirect'),
                        success: usedSettings.success
                    })
                }
            })
        ;
    }
   
    $.fn.isYoutubeVideo = function () {
        const $element = this;

        return (null !== $element.getFirstSource().match(youtubeRegex));
    };

    $.fn.getFirstSource = function () {
        const $element = this;

        return $element.find('source:first').attr('src');
    };

    $.fn.video = function () {
        const $element = this,
            isAutoplay = ($element.attr('autoplay') !== undefined),
            hasControls = ($element.attr('controls') !== undefined),
            isMuted = ($element.attr('muted') !== undefined),
            isPlaysinline =($element.attr('playsinline') !== undefined);

        if (true === $element.isYoutubeVideo()) {
            loadYoutubeApi(function () {
                player = new YT.Player($element[0], {
                // height: '360',
                // width: '640',
                videoId: getYoutubeId($element.getFirstSource()),
                playerVars: {
                    'modestbranding': 1,
                    'autoplay': isAutoplay,
                    'controls': hasControls,
                    'autohide': 1,
                    'muted': isMuted,
                    'wmode':'opaque',
                    'origin': window.location.origin 
                },
                // events: {
                //     'onReady': onPlayerReady,
                //     'onStateChange': onPlayerStateChange
                // }
                });
            });
        }
    };
   
    $.fn.groupBy = function (dataName) {
        const $elements = this;
        let groups = {};

        $elements.each(function (index, element) {
            const $element = $(element),
                dataGroups = $element.data(dataName);

            if (undefined !== dataGroups) {
                $.each(dataGroups.split(','), function (index, group) {
                    if (typeof groups[group] === 'undefined') {
                        groups[group] = [$element];
                    } else {
                        groups[group].push($element);
                    }
                });
            }
        });

        return groups;
    };

    $.fn.sortBy = function (criteria) {
        let criteriaIndex = 0;

        const sortByCriteria = function (a, b) {
            const $a = $(a),
                $b = $(b),
                dataA = $a.data('sorts'),
                dataB = $b.data('sorts'),
                currentCriteria = criteria[criteriaIndex];

            if (dataA[currentCriteria.sort] > dataB[currentCriteria.sort]) {
                criteriaIndex = 0;

                return currentCriteria.direction === 'desc' ? -1 : 1;
            } else if (dataA[currentCriteria.sort] < dataB[currentCriteria.sort]) {
                criteriaIndex = 0;

                return currentCriteria.direction === 'desc' ? 1 : -1;
            } else {
                if (typeof criteria[criteriaIndex + 1] !== 'undefined') {
                    criteriaIndex += 1;
                    return sortByCriteria(a, b);
                } else {
                    return 0;
                }
            }
        };
        
        return (this.sort(sortByCriteria));
    };

    $.fn.sortByData = function (dataName, direction = 'asc') {
        if (-1 === ['asc', 'desc'].indexOf(direction)) {
            console.error('direction "' + direction + '" doesn\t exists')
            return;
        }

        return (this.sort(function (a, b) {
            const $a = $(a),
                $b = $(b);

            if ($a.data(dataName) > $b.data(dataName)) {
                return direction === 'asc' ? 1 : -1;
            } else if ($a.data(dataName) < $b.data(dataName)) {
                return direction === 'asc' ? -1 : 1;
            } else {
                return 0;
            }
        }));
    };
   
    $.fn.sortByZindex = function () {
        return (this.sort(function (a, b) {
            if (parseInt(a.style.zIndex) > parseInt(b.style.zIndex)) {
                return -1;
            } else {
                return 1;
            }
        }));
    };

    $.fn.backgroundSize = function (callback, nocache = false) {
        var img = new Image(), width, height, backgroundSize = this.css('background-size').split(' ');
        const $self = $(this);

        if ($self.data('background-size') && false === nocache) {
            callback($self.data('background-size'));
            return this;
        }

        if (pxRegex.test(backgroundSize[0])) width = parseInt(backgroundSize[0]);
        if (percentRegex.test(backgroundSize[0])) width = this.parent().width() * (parseInt(backgroundSize[0]) / 100);
        if (vhRegex.test(backgroundSize[0])) width = $(window).width() / 100 * (parseInt(backgroundSize[0]));
        if (pxRegex.test(backgroundSize[1])) height = parseInt(backgroundSize[1]);
        if (percentRegex.test(backgroundSize[1])) height = this.parent().height() * (parseInt(backgroundSize[0]) / 100);
        if (vhRegex.test(backgroundSize[1])) height = $(window).height() / 100 * (parseInt(backgroundSize[1]));

        // additional performance boost, if width and height was set just call the callback and return
        if ((typeof width != 'undefined') && (typeof height != 'undefined')) {
            $self.data('background-size', { width: width, height: height });
            callback({ width: width, height: height });
            return this;
        }

        img.onload = function () {
            if (backgroundSize[0] === 'cover') {
                
                if ($self.width() / $self.height() >= width / height) {
                    width = $self.width();
                    height = parseInt(($self.width() / this.width) * this.height);
                } else {
                    height = $self.height();
                    width = parseInt(($self.height() / this.height) * this.width);
                }
            } else if (typeof width == 'undefined' && typeof height == 'undefined') {
                width = this.width;
                height = this.height;  
            } else if (typeof width == 'undefined') {
                width = height / this.height * this.width;
            } else if (typeof height == 'undefined') {
                height = width / this.width * this.height;
            }

            $self.data('background-size', { width: width, height: height });
            callback({ width: width, height: height });
        };

        img.src = this.css('background-image').replace(urlRegex, '$1');

        return this;
    };

    $.fn.dimensionClasses = function () {
        const dimensionClasses = ['height', 'width'];
        let classes = [];

        $.each(dimensionClasses, function (index, dimensionClass) {

        });

        return classes;        
    };

    $.fn.valueFromClassParametters = function (name, cssAttribute) {
        const value = this.classParameters(name)[0];

        if (value.match(numberRegex)) {
            this.css(cssAttribute, value);
        }
    };

    $.fn.backgroundPosition = function (callback) {
        const $element = this,
            backgroundPositions = $element.css('background-position').split(' ');

        $element.backgroundSize(function (dimensions) {
            positions = {};
            $.each(backgroundPositions, function (index, position) {
                if (-1 !== position.indexOf('%')) {
                    if (index == 0) {
                        positions.x = -(dimensions.width - $element.width()) * parseInt(position) / 100;
                    } else if (index == 1) {
                        positions.y = -(dimensions.height - $element.height()) * parseInt(position) / 100;
                    }
                } else if  (-1 !== position.indexOf('px')) {
                    if (index == 0) {
                        positions.x = parseInt(position);
                    } else if (index == 1) {
                        positions.y = parseInt(position);
                    } 
                }
            });

            callback(positions);
        })
    };    

    $.fn.cssImportant = function (name, value) {
        const style = this.attr('style');

        this.attr('style', (style !== undefined ? style.replace(new RegExp('\ *' + name + ':(.*?);'), '') : '') + ' ' + name + ': ' + value + ' !important;');

        return this;
    };

    $.fn.isVisible = function () {
        const rect = this[0].getBoundingClientRect();
        return (
            (rect.height > 0 || rect.width > 0) &&
            rect.bottom >= 0 &&
            rect.right >= 0 &&
            rect.top <= (window.innerHeight || document.documentElement.clientHeight) &&
            rect.left <= (window.innerWidth || document.documentElement.clientWidth)
        );
    };

    $.fn.isInViewport = function () {
        const viewportHeight = (window.innerHeight || document.documentElement.clientHeight),
            viewportWidth = (window.innerWidth || document.documentElement.clientWidth),
            rect = this[0].getBoundingClientRect(),
            percentage = 0.1,
            differenceHeight = (rect.height * percentage),
            differenceWidth = (rect.width * percentage);

        return (
            (rect.height > 0 || rect.width > 0) &&
            rect.top + rect.height - differenceHeight >= 0 &&
            rect.top + differenceHeight <= viewportHeight &&
            rect.left + rect.width - differenceWidth >= 0 &&
            rect.left + differenceWidth <= viewportWidth
        );
    };

    $.fn.classParameters = function (className){
        const element = this[0],
            result = [];

        if (typeof element.className == 'string') {
            $.each(element.className.split(' '), function (index, classEach) {
                if (-1 !== classEach.indexOf(className + '(')) {
                    result.push(classEach.replace(className + '(', '').replace(')', ''));
                }
            });
        }

        return result;
    };

    $.fn.classStart = function (classStart) {
        const element = this[0];

        if (typeof element.className == 'string') {
            return (-1 !== element.className.indexOf(' ' + classStart));
        }

        return false;
    };

    $.fn.notClassStart = function (classStart) {
        const element = this[0];

        if (typeof element.className == 'string') {
            return (-1 === element.className.indexOf(' ' + classStart));
        }

        return true;
    };

    $.fn.frameAnimateWithoutScroll = function (properties, duration, easing, complete) {
        complete = (typeof duration === 'function' ? duration : typeof easing === 'function' ? easing : typeof complete === 'function' ? complete : function () {});
        easing = (typeof duration === 'string' ? duration : typeof easing === 'string' ? easing : 'swing');
        duration =  ($.isNumeric(duration) ? duration : 400);

        const $element = $(this);

        $element.on('scroll mousedown wheel DOMMouseScroll mousewheel keyup touchmove', function () {
            $element.stop();
            complete();
        });

        $element.animate(properties, duration, easing, function () {
            $element.off('scroll mousedown wheel DOMMouseScroll mousewheel keyup touchmove');
            complete();
        });

        return this;
    };

    $.fn.slider = function (settings) {
        const $self = this;
        if (typeof settings === 'undefined' && typeof $self[0].swiper !== 'undefined') {
            return $self[0].swiper;
        }

        const $window = $(window),
            $pagination = $self.children('.pagination'),
            autoplayClass = $self.classParameters('autoplay'),
            speedClass = $self.classParameters('speed'),
            transitionClass = $self.classParameters('transition'),
            spaceClass = $self.classParameters('space'),
            slidesClass = $self.classParameters('slides'),
            spaceBeforeClass = $self.classParameters('spaceBefore'),
            spaceAfterClass = $self.classParameters('spaceAfter'),
            $thumb = $('.slider[data-target="' + $self.data('name') + '"]');

        var defaultSettings = {
            containerModifierClass : '',
            
            wrapperClass: 'slides',

            centeredSlides: $self.hasClass('slider(center)'),
            autoHeight: $self.hasClass('height(auto)'),
            watchOverflow: true,
            loop: $self.hasClass('slider(loop)'),
            freeMode: $self.hasClass('swipe(free)'),
            direction: $self.hasClass('slider(vertical)') ? 'vertical' : 'horizontal',
            a11y: false,
            watchSlidesVisibility: true,
            allowTouchMove: (false === $self.hasClass('swipe(none)')),
            
            slideClass: 'slide',
            slideActiveClass: 'active',
            slideVisibleClass: 'visible',
            slidePrevClass: 'previous',
            slideNextClass: 'next',

            navigation: {
                nextEl: $self.children('.controls').find('.next [class*="button"]'),
                prevEl: $self.children('.controls').find('.previous [class*="button"]'),
                disabledClass: 'disabled', // À globaliser peu importe le cas
            },

            slideDuplicateClass: 'duplicate',
            slideDuplicateActiveClass: 'duplicate active',
            slideDuplicatePrevClass: 'duplicate previous',
            slideDuplicateNextClass: 'duplicate next',
             on: {
                init: function () {
                    this.snapGrid = [...this.slidesGrid];
                    $self.trigger('init');
                },
            },
            history: false,
            hashNavigation: false,
        };

        if ($self.hasClass('slider(right)')) {
            $self.attr('dir', 'rtl');
        }

        if (false === $self.hasClass('keyboard(none)') && $self.data('target') === undefined) {
            defaultSettings.keyboard = {
                enabled: true,
                onlyInViewport: true
            };
        }


        if (true === $self.hasClass('mousewhell')) {
            defaultSettings.mousewheel = {
                forceToAxis: true,
                releaseOnEdges: false,
                invert: false,
                sensitivity: 1,
                eventsTarget: '.slider'
            };
        }

        if ($thumb.length > 0) {
            defaultSettings.thumbs = {
                slideThumbActiveClass: 'thumb-active',
                swiper: $thumb[0].swiper
            };
        }

        if ($pagination.length > 0) {
            const bulletsClass = $pagination.classParameters('bullets');

            if (false === $pagination.hasClass('scroll')) {
                defaultSettings.pagination = {
                    el: $pagination,
                    type :  $pagination.hasClass('progress') ? 'progressbar' : $pagination.hasClass('fraction') ? 'fraction' : 'bullets',
                    modifierClass: '',
                    totalClass: 'total',
                    hiddenClass: 'hidden',
                    activeClass: 'current',
                    disabledClass: 'disabled',
                    bulletClass: 'bullet',
                    bulletActiveClass: 'active',
                    dynamicBullets: false,
                    clickable: true,
                    clickableClass: 'clickable',

                    progressbarFillClass: 'position',
                    // progressbarOpposite: true,
                    renderProgressbar: function (progressbarFillClass) {
                        const swiper = this,
                        slidesLength = swiper.virtual && swiper.params.virtual.enabled ? swiper.virtual.slides.length : swiper.slides.length,
                        total = swiper.params.loop ? Math.ceil((slidesLength - (swiper.loopedSlides * 2)) / swiper.params.slidesPerGroup) : swiper.snapGrid.length;

                        let percent = 100 / total;

                        if (true === $self.hasClass('slider(vertical)')) {
                            return '<div class="bar" style="height: ' + percent + '%"></div>';
                        } else {
                            return '<div class="bar" style="width: ' + percent + '%"></div>';    
                        }
                    }
                };

                if ($pagination.hasClass('numbers') || $pagination.classParameters('numbers').length > 0) {
                    defaultSettings.pagination.bulletClass = 'number';    

                    defaultSettings.pagination.renderBullet = function (index, className) {
                      return '<div class="number">' + (index + 1) + '</div>';
                    };
                }

                if (bulletsClass.length > 0 && 'auto' === bulletsClass[0] ) {
                    defaultSettings.pagination.dynamicBullets = true;
                    defaultSettings.pagination.dynamicMainBullets = 1;
                } else if (bulletsClass.length > 0) {
                    defaultSettings.pagination.dynamicBullets = true;
                    defaultSettings.pagination.dynamicMainBullets = parseInt(bulletsClass[0]);
                }
            } else {
                defaultSettings.scrollbar = { 
                    el: $pagination,
                    draggable: true,
                    dragClass: 'bar',
                    dragSize: 'auto',
                };     
            }
        }

        let needChangeSpacesOnResize = false;

        if (spaceBeforeClass.length > 0) {
            if (false !== pixelToInt(spaceBeforeClass[0])) {
                defaultSettings.slidesOffsetBefore = pixelToInt(spaceBeforeClass[0]);
            } else if (-1 !== spaceBeforeClass[0].indexOf('vw')) {
                needChangeSpacesOnResize = true;

                defaultSettings.slidesOffsetBefore = $window.width() * parseInt(spaceBeforeClass[0]) / 100;
            } else if (-1 !== spaceBeforeClass[0].indexOf('vh')) {
                needChangeSpacesOnResize = true;

                defaultSettings.slidesOffsetBefore = $window.height() * parseInt(spaceBeforeClass[0]) / 100;
            } else if (-1 !== spaceBeforeClass[0].indexOf('%')) {
                needChangeSpacesOnResize = true;

                if (false === $self.hasClass('slider(vertical)')) {
                    defaultSettings.slidesOffsetBefore = $self.width() * parseInt(spaceBeforeClass[0]) / 100;
                } else {
                    defaultSettings.slidesOffsetBefore = $self.height() * parseInt(spaceBeforeClass[0]) / 100;
                }
            }

        }

        if (spaceAfterClass.length > 0) {
            if (false !== pixelToInt(spaceAfterClass[0])) {
                defaultSettings.slidesOffsetAfter = pixelToInt(spaceAfterClass[0]);
            } else if (-1 !== spaceAfterClass[0].indexOf('vw')) {
                needChangeSpacesOnResize = true;

                defaultSettings.slidesOffsetAfter = $window.width() * parseInt(spaceAfterClass[0]) / 100;
            } else if (-1 !== spaceAfterClass[0].indexOf('vh')) {
                needChangeSpacesOnResize = true;

                defaultSettings.slidesOffsetAfter = $window.height() * parseInt(spaceAfterClass[0]) / 100;
            } else if (-1 !== spaceAfterClass[0].indexOf('%')) {
                needChangeSpacesOnResize = true;

                if (false === $self.hasClass('slider(vertical)')) {
                    defaultSettings.slidesOffsetAfter = $self.width() * parseInt(spaceAfterClass[0]) / 100;
                } else {
                    defaultSettings.slidesOffsetAfter = $self.height() * parseInt(spaceAfterClass[0]) / 100;
                }
            }
        }

        if (true === needChangeSpacesOnResize) {
            $(window).on('resize orientationchange', function () {
                if (-1 !== spaceBeforeClass[0].indexOf('vw')) {
                    $self[0].swiper.params.slidesOffsetBefore = $window.width() * parseInt(spaceBeforeClass[0]) / 100;
                } else if (-1 !== spaceBeforeClass[0].indexOf('vh')) {
                    $self[0].swiper.params.slidesOffsetBefore = $window.height() * parseInt(spaceBeforeClass[0]) / 100;
                } else if (-1 !== spaceBeforeClass[0].indexOf('%')) {
                    if (false === $self.hasClass('slider(vertical)')) {
                        $self[0].swiper.params.slidesOffsetBefore = $self.parent().width() * parseInt(spaceBeforeClass[0]) / 100;
                    } else {
                        $self[0].swiper.params.slidesOffsetBefore = $self.parent().height() * parseInt(spaceBeforeClass[0]) / 100;
                    }
                }

                if (-1 !== spaceAfterClass[0].indexOf('vw')) {
                    $self[0].swiper.params.slidesOffsetAfter = $window.width() * parseInt(spaceAfterClass[0]) / 100;
                } else if (-1 !== spaceAfterClass[0].indexOf('vh')) {
                    $self[0].swiper.params.slidesOffsetAfter = $window.height() * parseInt(spaceAfterClass[0]) / 100;
                } else if (-1 !== spaceAfterClass[0].indexOf('%')) {
                    if (false === $self.hasClass('slider(vertical)')) {
                        $self[0].swiper.params.slidesOffsetAfter = $self.parent().width() * parseInt(spaceAfterClass[0]) / 100;
                    } else {
                        $self[0].swiper.params.slidesOffsetAfter = $self.parent().height() * parseInt(spaceAfterClass[0]) / 100;
                    }
                }

                $self[0].swiper.update();
            });            
        }

        if (slidesClass.length > 0) {
            defaultSettings.slidesPerView = parseInt(slidesClass[0]);
        } else {
            defaultSettings.slidesPerView = 'auto';
        }

        if (spaceClass.length > 0 && false !== pixelToInt(spaceClass[0])) {
            defaultSettings.spaceBetween = pixelToInt(spaceClass[0]);
        } else {
            defaultSettings.spaceBetween = 20;
        }

        if (autoplayClass.length > 0 && humanTimeToMillisecond(autoplayClass[0])) {
            defaultSettings.autoplay = {
                delay: humanTimeToMillisecond(autoplayClass[0]),
            };
        }

        if (speedClass.length > 0 && humanTimeToMillisecond(speedClass[0])) {
            defaultSettings.speed = humanTimeToMillisecond(speedClass[0]);
        }

        if (transitionClass.length > 0 && -1 !== ['slide', 'fade', 'cube', 'coverflow', 'flip'].indexOf(transitionClass[0])) {
            defaultSettings.effect = transitionClass[0];
        }

        if ($self.parents('.slider').length > 0) {
            defaultSettings.nested = true;
            $self.addClass('nested');
        }

        defaultSettings = $.extend(true, defaultSettings, settings);

        const swiper = new Swiper($self[0], defaultSettings);

        if (typeof defaultSettings.autoplay !== 'undefined' && $self.hasClass('hover/pause')) {
            $self.on('mouseover', function (event) {
                $self.swiper.autoplay.stop();
            }).on('mouseleave', function (event) {
                $self.swiper.autoplay.start();
            });
        }

        swiper.on('transitionEnd', function () {
            $self.find('.animation.motion\\\(repeat\\\)').each(function(index, element) {
                const $element = $(element);

                $element.on('animationend webkitAnimationEnd', function (event) {
                    $element.removeClass('running');
                    $element.off('animationend webkitAnimationEnd');
                });

                $element.addClass('running');
                $element.removeClass('animation');
            });

            $self.find('.slides >.slide.visible.active').find('[class*="motion("]').addClass('animation');
        });

        swiper.on('slideChangeTransitionEnd', function () {
            $self.find('.slides >.slide.visible.active').each(function (index, slide) {
                const $slide = $(slide);

                if ($slide.is('[class*="motion("]')) {
                    $slide.addClass('animation');
                }
                
                if ($slide.data(showAction)) {
                    $($slide.data(showAction).split(',')).each( function (index, action) {
                        $('[data-name="' +  action +'"]').frameShow();
                    });
                }

                $self.find('.slides > .slide:not(.visible.active)').each(function (index, slide) {
                    const $slide = $(slide);
                    
                    if ($slide.data(showAction)) {
                        $($slide.data(showAction).split(',')).each( function (index, action) {
                            $('[data-name="' +  action +'"]').frameHide();
                        });
                    }
                });
            });
        });


        if ($pagination.length > 0 && $pagination.hasClass('progress')) {
            swiper.on('transitionEnd', function () {
                const swiper = this,
                    slidesLength = swiper.virtual && swiper.params.virtual.enabled ? swiper.virtual.slides.length : swiper.slides.length,
                    total = swiper.params.loop ? Math.ceil((slidesLength - (swiper.loopedSlides * 2)) / swiper.params.slidesPerGroup) : swiper.snapGrid.length;

                let percent = (100 / total) * (swiper.realIndex + 1);

                if (true === swiper.isEnd && false === swiper.params.loop) {
                    percent = 100;
                }

                if ('vertical' === swiper.params.direction) {
                    $self.children('.pagination.progress').find('.bar').height(percent + '%');
                } else {
                    $self.children('.pagination.progress').find('.bar').width(percent + '%');
                }
            });

            $pagination.removeClass('progressbar');
        }

        if ($pagination.length > 0 && ($pagination.hasClass('numbers') || $pagination.classParameters('numbers').length > 0)) {
            $pagination.removeClass('bullets');
        }

        swiper.init();

        return $self.swiper;
    };

    $.fn.removeSlider = function (settings) {
        const $self = this;

        if (typeof $self[0].swiper !== 'undefined' && null !== $self[0].swiper) {
            $self[0].swiper.destroy(true, false);
            delete $self[0].swiper;
        }
    };

    $.fn.recursiveChildren = function (callback = function (element){} ) {
        $self = this;

        $self.children().each(function (index, child) {
            const $child = $(child);

            callback($child);

            $child.recursiveChildren(callback);
        });
    };

    $.fn.isParentsOf = function ($searchedElement) {
        let elementsCollection = [];

        this.each(function (index, element) {
            const $element = $(element);

            if ($element.parents($searchedElement).length > 0) {
                elementsCollection.push($element);
            }
        });

        return $(elementsCollection);
    };

    $.fn.isNotParentsOf = function ($searchedElement) {
        let elementsCollection = [];

        this.each(function (index, element) {
            const $element = $(element);

            if ($element.parents($searchedElement).length === 0) {
                elementsCollection.push($element);
            }
        });

        return $(elementsCollection);
    };

    $.fn.notParents = function ($searchedElement) {
        let elementsCollection = [];

        this.each(function (index, element) {
            const $element = $(element);

            if ($element.has($searchedElement).length === 0) {
                elementsCollection.push($element);
            }
        });

        return $(elementsCollection);
    };

    $.fn.isShow = function () {
        const dataShowed = this.data('showed');

        if (undefined === dataShowed) {
            return false;
        } else {
            return dataShowed;
        }
    };

    $.fn.frameShow = function () {
        const $html = $('html');

        return this.each(function (index, element) {
            const $element = $(element),
                elementName = $element.data('name') ? $element.data('name').toString() : null,
                $siblings = $element.siblings(),
                $opener = $('[data-' + showAction + '="' + elementName + '"],[data-' + showAction + '*="' + elementName + ',"]'),

                $openers = $opener.siblings(),
                $divFirstAnimation = $element.children('div[class*=" motion("],div[class^="motion("]');
                $childrenSlider = $element.find('.slider');

            if ($element.hasClass('show')) {
                return;
            }

            if (true === $element.isShow()) {
                return;
            }

            $element.data('showed', true);

            var history = false;

            $element.on('show', function (event) {
                // initialize tab
                let tabGroups = {};
                let tabGroupsAutoplay = {};
                $element.find('.dialog.tab[data-group]').each(function (index, tab) {
                    const $tab = $(tab),
                        group = $tab.data('group');

                    if (typeof tabGroups[group] === 'undefined') {
                        tabGroups[group] = [$tab];
                    } else {
                        tabGroups[group].push($tab);
                    }

                    if ($tab.classStart('autoplay(') || typeof tabGroupsAutoplay[group] !== 'undefined') {
                        if (typeof tabGroupsAutoplay[group] === 'undefined') {
                            tabGroupsAutoplay[group] = [$tab];
                        } else {
                            tabGroupsAutoplay[group].push($tab);
                        }
                    }
                });

                $.each(tabGroups, function (group, tabs) {
                    let indexToOpen = 0;

                    for (let i = 0; i < tabs.length; i++) {
                        if (tabs[i].hasClass('load/dialog')) {
                            indexToOpen = i;
                            break;
                        }
                    } 

                    tabs[indexToOpen].frameShow();
                });

                $.each(tabGroupsAutoplay, function (group, tabs) {
                    const $firstTab = $(tabs[0]),
                        autoplayClass = $firstTab.classParameters('autoplay');

                    if (autoplayClass.length > 0 && humanTimeToMillisecond(autoplayClass[0])) {
                        const delay = humanTimeToMillisecond(autoplayClass[0]);

                        window.setInterval(function (tabs) {
                            let lastIndex = 0,
                                tabToShowIndex = 0;

                            $.each(tabs, function (index, tab) {
                                if (tab.isShow()) {
                                    lastIndex = index;
                                    tab.frameHide();
                                }
                            });

                            tabs[lastIndex + 1 == tabs.length ? 0 : lastIndex + 1].frameShow();
                        }, delay, tabs);
                    }

                    return;
                });

                $element.children('div').find('[class*="motion("]').each(function (index, motionElement) {
                    const $motionElement = $(motionElement);
                    if ($motionElement.isInViewport() && $motionElement.parents('.slider').length === 0 ) {
               
                         $motionElement
                            .css('animation-play-state', '')
                            .addClass('running')
                            .on('animationend', function (event) {
                                $(event.target).removeClass('running')
                                    .off('animationend')
                                ;

                                event.stopPropagation();
                            })
                        ;

                    }
                });

                $element.find('.slider.initialized > .slides >.slide.visible.active').each(function (index, element) {
                    const $element = $(element);
                    if (true === $element.isInViewport()) {
                        $element.find('[class*="motion("]:not(.animation)').addClass('animation');    
                    }
                });

                playActionOnTarget();

                $element.off(event);
                event.stopPropagation();
            });

            if ($element.is('.dialog[class*=" dropdown"],.dialog[class^="dropdown"]')) {
                if ($element.hasClass('dropdown(full)')) {
                    $element.css({
                        'left': -$opener.position().left - 1,
                        'top': $opener.outerHeight(false),
                        //'position': 'fixed',
                        //'top': $opener.offset().top - $(window).scrollTop() + $opener.outerHeight(false),
                        //'left': 0,
                    });
                } else if ($element.hasClass('dropdownTop')) {
                    $element.css('bottom', $opener.outerHeight(false));
                } else if ($element.hasClass('dropdownTopLeft')) {
                    $element.css({
                        'right': 0,
                        'bottom': $opener.outerHeight(false)

                    });
                } else if ($element.hasClass('dropdownTopRight')) {
                    $element.css({
                        'left': 0,
                        'bottom': $opener.outerHeight(false)

                    });
                } else if ($element.hasClass('dropdown') || $element.hasClass('dropdownBottom')) {
                    $element.css('top', $opener.outerHeight(false));
                } else if ($element.hasClass('dropdownBottomLeft')) {
                    $element.css({
                        'right': 0,
                        'top': $opener.outerHeight(false)

                    });
                } else if ($element.hasClass('dropdownBottomRight')) {
                    $element.css({
                        'left': 0,
                        'top': $opener.outerHeight(false)

                    });
                } else if ($element.hasClass('dropdownLeft')) {
                    $element.css('right', $opener.outerWidth(false)); // - largeur de la div.dropdown
                } else if ($element.hasClass('dropdownLeftTop')) {
                    $element.css({
                        'right': $opener.outerWidth(false),
                        'bottom': 0
                    });
                } else if ($element.hasClass('dropdownLeftBottom')) {
                    $element.css({
                        'right': $opener.outerWidth(false),
                        'top': 0

                    });
                } else if ($element.hasClass('dropdownRight')) {
                    $element.css('left', $opener.outerWidth(false)); // - largeur de la div.dropdown
                } else if ($element.hasClass('dropdownRightTop')) {
                    $element.css({
                        'left': $opener.outerWidth(false),
                        'bottom': 0
                    });
                } else if ($element.hasClass('dropdownRightBottom')) {
                    $element.css({
                        'left': $opener.outerWidth(false),
                        'top': 0
                    });
                }
            }

            $element.find('.delayAuto').each(function (index, elementDelayAuto) {
                $(elementDelayAuto).find('[class*="motion("]').not('aside').each(function (index, element) {
                    const $element = $(element);

                    if ($element.notClassStart('delay(')) {
                        $element.css('animation-delay', ((index + 1) * 250) + 'ms');    
                    }
                });
            });

            $element.find('[class*=" delayAuto("],[class^="delayAuto("]').not('aside').each(function (index, elementDelayAuto) {
                const $elementDelayAuto = $(elementDelayAuto)
                    delay = humanTimeToMillisecond($elementDelayAuto.classParameters('delayAuto')[0]);

                $(elementDelayAuto).find('[class*="motion("]').each(function (index, element) {
                    const $element = $(element);

                    if ($element.notClassStart('delay(')) {
                        $element.css('animation-delay', ((index + 1) * delay) + 'ms');
                    }
                });
            });

            $element.addClass('show');
            blockScroll();

            if ($element.is('[class*="motion("]') && (0 === $element.parents('main').length || (0 !== $element.parents('main').length && true === $element.isInViewport()))) {
                $element.addClass('animation running')
                    .on('animationend', function (event) {
                        $element.removeClass('running')
                            .off('animationend');
                        calculatePersistent();
                        $element.trigger('show');
                        event.stopPropagation();
                    })
                ;
            }

            if ($divFirstAnimation.is('[class*="motion("]') && (0 === $element.parents('main').length || (0 !== $element.parents('main').length && true === $element.isInViewport()))) {
                $divFirstAnimation.addClass('animation running')
                    .on('animationend', function (event) {
                        $(event.target).removeClass('running')
                            .off('animationend');
                        calculatePersistent();
                        $element.trigger('show');
                        event.stopPropagation();
                    })
                ;
            }

            $element.children('div').find('[class*="motion("]').each(function (index, motionElement) {
                const $motionElement = $(motionElement);
                if (true === $motionElement.closest('aside').is($element) && $motionElement.parents('.slider').length === 0) {
                    if (false === $element.hasClass('content/animation(backwards)') && false === $motionElement.hasClass('animation(backwards)')) {
                        $motionElement.addClass('animation(forwards)');    
                    }
                    $motionElement
                        .css('animation-play-state', 'paused')
                        .addClass('animation')
                        ;
                }
            });
            
            if ((false === $element.is('[class*="motion("]') && false === $divFirstAnimation.is('[class*="motion("]')) || (0 !== $element.parents('main').length && false === $element.isInViewport())) {
                $element.trigger('show');
            }

            $opener.addClass('open');

            if ($element.hasClass('dialog')) {
                var dialogs;
                history = true;
                
                $html.addClass('dialog');

                if (undefined === (dialogs = $html.data('dialog')) || 0 === $html.data('dialog').length) {
                    dialogs = [];
                    $html.on('click touchstart', dialogClickOutsideHandler);
                }

                if (! $element.is('[class*="persistent"]')) {
                    $element.cssImportant('z-index', (minZindex + dialogs.length));
                }

                dialogs.push(elementName);

                $html.data('dialog', dialogs);

                if ($element.hasClass('tab')) {
                    $('aside.dialog.tab[data-group="' + $element.data('group') + '"]').not($element).removeClass('load/dialog');
                    $element.addClass('load/dialog');

                    history = false;
                } else if ($element.hasClass('collapse')) {                    
                    $opener.addClass('animation');

                    history = false;
                } else if ($element.is('.dialog[class*=" dropdown"],.dialog[class^="dropdown"]')) {
                    const $divFirst = $element.children('div:first-of-type'),
                        $dropdowns = $('aside.dialog[class*=" dropdown"].show,aside.dialog[class^="dropdown"].show').not($element).notParents($element);

                    if ($element.hasClass('dropdown(full)')) {
                        $element.cssImportant('width', '100vw');
                        $element.cssImportant('z-index', '10000');
                    } else {
                        let width = 0;

                        $divFirst.recursiveChildren(function ($child) {
                            const childWidth = $child.outerWidth(true);

                            if (childWidth > width) {
                                width = childWidth;
                            }
                        });

                        width += parseInt($divFirst.css('padding-right'));
                        width += parseInt($divFirst.css('padding-left'));
                        width += parseInt($divFirst.css('margin-right'));
                        width += parseInt($divFirst.css('margin-left'));

                        $element.cssImportant('width', width + 'px');
                    }

                    $opener.addClass('animation');
                    $dropdowns.frameHide();

                    history = false;
                } else if ($element.hasClass('filter')) {
                    history = false;
                } else if ($element.is('.dialog.show.persistent\\\(top\\\)[class*="alert"]')) {
                    const $stickedElements = $('.position\\\(sticky\\\).sticked'),
                        $divFirst = $element.children('div:first-of-type');

                    $stickedElements.each(function (index, stickedElement) {
                        const $stickedElement = $(stickedElement);

                        $stickedElement.cssImportant('top', ($stickedElement.position().top + $divFirst.outerHeight()) + 'px');
                    });
                }

                calculatePersistent();

                const $sliders = $element.find('.slider');
                
                $sliders.each(function (index, slider) {
                    const $slider = $(slider);

                    if (typeof slider.swiper !== 'undefined') {
                        if ($slider.data('name') === undefined || $('.slider[data-target="' + $slider.data('name') + '"]').length == 0) {
                            slider.swiper.slideTo(0);    
                        }

                        slider.swiper.update();                        
                    }
                });
            } else if($element.hasClass('slide')) {
                const $slider = $element.parents('.slider'),
                    openerMethod = $opener.data('method');

                $element.removeClass('show');

                if (false === $slider.is('[class*="autoplay("]')) {
                    let limit = $slider.offset().top;

                    if (undefined === openerMethod || 'headerInside' !== openerMethod) {
                        $('body > header,.persistent\\\(top\\\) > div:first-of-type,.position\\\(sticky\\\).sticked').each(function (index, elementTop) {
                            const $elementTop = $(elementTop);

                            limit -= $elementTop.outerHeight();
                        });                    
                    }

                    $html.frameAnimateWithoutScroll({ scrollTop: limit }, function () {
                        var indexFund = 0;
                        $slider.find('.slides .slide').each(function (index, slide) {
                            if ($(slide).data('name') == elementName) {
                                indexFund = index;
                            }
                        });

                        $slider.slider().slideToLoop(indexFund);

                        $element.removeClass('show');
                        $opener.removeClass('open');
                        $element.data('showed', false);
                    });
                }

            } else {
                const openerMethod = $opener.data('method');

                $element.removeClass('show');

                let limit = $element.offset().top;

                if (undefined === openerMethod || 'headerInside' !== openerMethod) {
                    $('body > header,.persistent\\\(top\\\) > div:first-of-type,.position\\\(sticky\\\).sticked').each(function (index, elementTop) {
                        const $elementTop = $(elementTop);

                        limit -= $elementTop.outerHeight();
                    });                    
                }

                $html.frameAnimateWithoutScroll({ scrollTop: limit }, function () {
                    $element.removeClass('show');
                    $opener.removeClass('open');
                    $element.data('showed', false);
                });
            }

            $childrenSlider.each(function (index, slider) {
                $(slider).slider();
            });

            if ($element.hasClass('dialog(mandatory)')) {
                $html.addClass('mandatory');
            }

            if (true === history && false === $element.hasClass('dialog(secret)') && undefined !== elementName) {
                window.history.pushState(elementName, document.title, window.location.pathname + window.location.search + addHash(elementName));
            }

        }); // End Each
    }; // End show

    $.fn.frameHide = function () {
        const $html = $('html');

        this.each(function (index, element) {
            const $element = $(element),
                elementName = $element.data('name'),
                $opener = $('[data-' + showAction + '="' + elementName + '"],[data-' + showAction + '*="' + elementName + ',"]'),
                $firstDiv = $element.children('div:first-of-type');

            var history = false;

            $element.on('hide', function(event) {
                $element.find('aside.dialog.tab').frameHide();
                event.stopPropagation();
                blockScroll();
            });

            if (false === $element.isShow()) {
                $element.trigger('hide');
                return;
            }

            $element.data('showed', false);

            if (($element.is('[class*="motion("]') && $element.hasClass('animation')) || ($firstDiv.is('[class*="motion("]') && $firstDiv.hasClass('animation'))) {

                if ($element.is('[class*="motion("]')) {
                    $element.addClass('running');
                    $element.removeClass('animation');

                    $element.on('animationend webkitAnimationEnd', function (event) {

                        $element.removeClass('running');

                        if (false === $firstDiv.hasClass('running')) {
                            $element.removeClass('show');
                            $element.trigger('hide');
                            $element.css('z-index', '');
                            blockScroll();
                        }

                        $element.off('animationend webkitAnimationEnd');

                        event.stopPropagation();
                    });
                }

                if ($firstDiv.is('[class*="motion("]')) {
                    $firstDiv.addClass('running');
                    $firstDiv.removeClass('animation');

                    $firstDiv.on('animationend webkitAnimationEnd', function (event) {

                        $firstDiv.removeClass('running');

                        if (false === $element.hasClass('running')) {
                            $element.removeClass('show');
                            $element.trigger('hide');
                            $element.css('z-index', '');
                            blockScroll();
                        }

                        $firstDiv.off('animationend webkitAnimationEnd');

                        event.stopPropagation();
                    });
                }
            } else {
                if ($element.is('.dialog.persistent\\\(top\\\)[class*="alert"]')) {
                    const $stickedElements = $('.position\\\(sticky\\\).sticked');

                    $stickedElements.each(function (index, stickedElement) {
                        const $stickedElement = $(stickedElement);

                        $stickedElement.cssImportant('top', ($stickedElement.position().top - $firstDiv.outerHeight()) + 'px');
                    });
                }
                $element.removeClass('show');
                $element.trigger('hide');
                $element.css('z-index', '');
                blockScroll();
            }

            $element.children('div').find('[class*="motion("]').removeClass('animation running');

            $opener.removeClass('open');

            if ($element.hasClass('dialog')) {
               var dialogs;
               history = true;
                
                $html.removeClass('dialog');

                if (undefined === (dialogs = $html.data('dialog'))) {
                    dialogs = [];
                }

                dialogs.remove(elementName);
                $html.data('dialog', dialogs);

                if (undefined === (dialogs = $html.data('dialog')) || 0 === $html.data('dialog').length) {
                    $html.off('click touchstart', dialogClickOutsideHandler);
                } else if ($element.is('.dialog.persistent\\\(top\\\)[class*="alert"]')) {
                    $('main').css('padding-top', '');
                    $('header').css('top', '');
                    $('.height\\\(full\\\)').css({
                        'height': '',
                        'min-height': '',
                    });
                } else if ($element.is('.dialog.persistent\\\(bottom\\\)[class*="alert"]')) {
                    $('main').css('padding-bottom', '');
                    $('footer').css('margin-bottom', '');
                    $('.height\\\(full\\\)').css({
                        'height': '',
                        'min-height': '',
                    });
                } else if ($element.is('.dialog.persistent\\\(left\\\)[class*="sidebar"]')) {
                    $('main').css('padding-left', '');
                    $('header').css({
                        'width': '',
                        'right': '',
                    });

                    $('.width\\\(full\\\)').css({
                        'width' : '',
                        'min-width': '',
                    });
                } else if ($element.is('.dialog.persistent\\\(right\\\)[class*="sidebar"]')) {
                    $('main').css('padding-right', '');
                    $('header').css({
                        'width': '',
                        'left': '',
                    });

                    $('.width\\\(full\\\)').css({
                        'width' : '',
                        'min-width': '',
                    });
                }
            } else if ($element.hasClass('collapse')) {
                $opener.removeClass('animation');
            }else if ($opener.hasClass('dropdown')) {
                $html.removeClass('dropdown');
                $opener.removeClass('animation');
            }

            if ($element.hasClass('dialog(mandatory)')) {
                $html.removeClass('mandatory');
            }

            if (true === history && false === $element.hasClass('dialog(secret)') && undefined !== elementName) {
                window.history.pushState('close', document.title, window.location.pathname + window.location.search + removeHash(elementName));
            }
        });

        return this;
    }; // End Hide

    $.fn.frameScrollTo = function (noAnimation = false, duration = 400) {
        const $html = $('html');

        this.each(function (index, element) {
            const $element = $(element);

            let limit = $element.offset().top;

            if (0 !== limit) {
                $('body > header,.persistent\\\(top\\\) > div:first-of-type,.position\\\(sticky\\\).sticked').each(function (index, elementTop) {
                    const $elementTop = $(elementTop);

                    limit -= $elementTop.outerHeight();
                });

                if (false === noAnimation) {
                    $html.frameAnimateWithoutScroll({ scrollTop: limit }, duration);
                } else {
                    $html.get(0).scrollTop = limit;
                }                
            }
        });
    };

    // Document ready
    $(document).ready(function () {
        // Initialize
        $.frameInitialize();

    }); // End document ready
})( jQuery );